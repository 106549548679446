import {RouteFactory} from "../../../navigation/RouteFactory";
import {StateConstants} from "../../stateMachines/StateConstants";
import {MenuRouteInterface} from "../../../navigation/NavigationRoutesInterface";


export class GetNavigationMenuRouteIndex {
    /**
     * If we can find a navigation menu route we return it's index. Otherwise we return -1.
     * @param authenticationState
     * @param routePath
     */
    static init(authenticationState: StateConstants, routePath: string): any{
        const objRouteFactory = new RouteFactory(authenticationState);
        const availableRoutesForAuthState = objRouteFactory.getUsersMenuBarRoutes();
        for(let i = 0; i < availableRoutesForAuthState.length; i++){
            const route: MenuRouteInterface = availableRoutesForAuthState[i];
            if(route.path  === routePath) {
                return i;
            }
        }
        return 0;
    }
}