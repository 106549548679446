import React from 'react'
import {Divider} from '@material-ui/core'
import {DrawerDividerComponentStyles} from './DrawerDividerComponentStyles'
import {ClassNameMap} from '@material-ui/styles'

export default function DrawerDividerComponent() {
  const styles: ClassNameMap = DrawerDividerComponentStyles()

  return <Divider className={styles.root} />
}
