import history from "../../navigation/history";
import state from '../../store/configureStore'
import {SET_AUTH_STATE} from "../../actions";
import AuthenticationStateMachine from "../stateMachines/AuthenticationStateMachine";
import {TransitionConstants} from "../stateMachines/StateConstants";
import SnackbarNotificationModel from "../../modals/SnackbarNotificationModel";
import {NetworkConnectionError} from "../errors/NetworkConnectionError";
import {NoConnectionError} from "../errors/NoConnectionError";
import {APIConnectionError} from "../errors/APIConnectionError";

export class ApiErrorHandler {
    /**
     * @throws Error
     * @param error
     */
    static init(error: any){
        // Error 😨
        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             * data = error.response.data
             * status = error.response.status
             * headers = error.response.headers
             */
            if(error.response.status === 401){
                const resultantState = AuthenticationStateMachine.transition(
                    state.store.getState().auth.currentAuthState,
                    TransitionConstants.EXIT_CARD_ONLY_USER,
                ).value
                state.store.dispatch({type: SET_AUTH_STATE, currentAuthState: resultantState})
                history.replace('/');
                SnackbarNotificationModel.error('Your session expired');
            } else {
                throw new APIConnectionError(error);
            }
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            throw new NetworkConnectionError(error);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            throw new NoConnectionError(error);
        }
    }
}