import React from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import clsx from 'clsx';
import FooterAddressComponent from '../../components/Footer/FooterAddress/FooterAddressComponent'
import FooterContactComponent from '../../components/Footer/FooterContact/FooterContactComponent'
import FooterDisclaimerComponent from '../../components/Footer/FooterDisclaimer/FooterDisclaimerComponent'
import BankDisclaimerComponent from '../../components/Footer/BankDisclaimer/BankDisclaimerComponent'
import CompanyDisclaimerComponent from '../../components/Footer/CompanyDisclaimer/CompanyDisclaimerComponent'
import FooterCopyrightComponent from '../../components/Footer/FooterCopyright/FooterCopyrightComponent'
import { BaseFooterLayoutStyles } from './BaseFooterLayoutStyles'
import { ClassNameMap } from '@material-ui/styles'
import {useIsMobile} from '../../hooks/useIsMobile'
import {DrawerContextInterface, useDrawerContext} from '../../hooks/DrawerContext'

export default function BaseFooterLayout() {
  const styles: ClassNameMap = BaseFooterLayoutStyles()
  const program: RootStateOrAny = useSelector<RootStateOrAny>(state => state.program?.program)

  const isMobile: boolean = useIsMobile();
  const { drawerIsOpen }: DrawerContextInterface = useDrawerContext();

  if(typeof program === 'undefined'){
    return (<div/>);
  }

  return (
      <footer className={clsx(styles.footer, {
        [styles.footerShift]: drawerIsOpen && !isMobile
      })}>

          <Grid container style={{padding: 8}}>
            <Grid container item direction={'row'} spacing={2} className={styles.topFooterSection} >

              <Grid item xs={12} sm={4}>
                  <FooterAddressComponent />
              </Grid>

              <Grid item xs={12} sm={4}>
                  <FooterContactComponent />
              </Grid>

              <Grid item xs={12} sm={4}>
                  <FooterDisclaimerComponent />
              </Grid>
            </Grid>
          </Grid>

          <Grid container style={{padding: 8}}>
            <Grid container item direction={'column'} spacing={2} className={styles.bottomFooterSection}>
              <Grid item>
                <BankDisclaimerComponent />
              </Grid>

              <Grid item>
                <CompanyDisclaimerComponent />
              </Grid>

              <Grid item>
                <FooterCopyrightComponent />
              </Grid>
            </Grid>
          </Grid>

      </footer>
  
  )
}
