import {makeStyles} from '@material-ui/core/styles'
import {Theme} from '@material-ui/core'

export const StyledTextFieldComponentStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%',
    maxWidth: '18rem',
    margin: theme.spacing(1, 'auto'),
  },
  inputLabelProps: {
    color: theme.components.form.textFieldColor,
    "&.Mui-disabled": {
      color: theme.components.form.textFieldDisabledColor
    },
    "&.Mui-focused": {
      color: theme.components.form.textFieldFocusColor
    },
  },
  inputProps: {
    color: theme.components.form.textFieldColor,
    "&.Mui-disabled": {
      color: theme.components.form.textFieldDisabledColor
    },
    "&.MuiInput-underline:before": {
      borderBottom: `1px solid ${theme.components.form.textFieldFocusBorderColor}`
    },
    "&.MuiInput-underline:after": {
      borderBottom: `2px solid ${theme.components.form.textFieldFocusBorderColor}`
    }
  }
}))