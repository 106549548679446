import jwt from 'jsonwebtoken'

export enum TOKEN_TYPES {
  CARD_VALIDATED = 'CARD_VALIDATED',
  CARD_AUTHENTICATED_AKA_VERIFIED = 'CARD_AUTHENTICATED_AKA_VERIFIED',
  USER_AUTHENTICATED = 'USER_AUTHENTICATED',
}

export default class MockedJWTInteractor {
  public static createMockJWTToken(payload: string, tokenType: TOKEN_TYPES) {
    return jwt.sign(payload, tokenType)
  }

  public static verifyToken(token: string, tokenType: TOKEN_TYPES): boolean {
    const decoded = jwt.verify(token, tokenType)
    return decoded !== undefined
  }
}
