import React from 'react';
import { Grid } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'
import HelpTitleComponent from '../../components/Help/HelpTitle/HelpTitleComponent'
import HelpDescriptionComponent from '../../components/Help/HelpDescription/HelpDescriptionComponent'
import {BaseHelpLayoutStyles} from './BaseHelpLayoutStyles'

export default function BaseHelpLayout() {
  const styles: ClassNameMap = BaseHelpLayoutStyles()

  return (
    <Grid
      container
      direction={'column'}
      alignItems={'center'}
      id="help"
    >
      <Grid 
        item 
        xs={12} sm={12} md={6} lg={6}
        className={styles.wrapper}
      >
        <Grid item>
          <HelpTitleComponent/>
        </Grid>

        <Grid
          container
          direction={'row'}
          justify={'center'}
        >
          <HelpDescriptionComponent />

        </Grid>
      </Grid>
    </Grid>
  )
}