import {SetCardData} from "./SetCardData";
import {SetCardAccessToken} from "./SetCardAccessToken";
import SnackbarUtils from "../../../modals/SnackbarNotificationModel";
import {SetAuthState} from "../Auth/SetAuthState";
import {TransitionConstants} from "../../stateMachines/StateConstants";
import {AxiosResponse} from "axios";

export class ExecuteSecondaryValidationAction {
    /**
     * @throws Error
     * @param authenticationResponse
     * @param cardNumber
     * @param currentAuthState
     * @param history
     * @param destinationRoute
     * @param cardActivationStatusAction
     */
    public static async init(authenticationResponse: AxiosResponse, cardNumber: string, currentAuthState: string, history: any, destinationRoute: string, cardActivationStatusAction: (cardNumber: number) => Promise<any> | void) {
        SetCardData.init({
            cardData: {
                lastFourDigitsOfCardNumber: cardNumber.substring(cardNumber.length - 4, cardNumber.length),
            }
        })
        SetCardAccessToken.init(authenticationResponse.data.data.authentication_access_token)
        await cardActivationStatusAction(authenticationResponse.data.data.status)
        SetAuthState.init(currentAuthState, TransitionConstants.CHECK_CARD_BALANCE)
        history.replace(destinationRoute)
    }
}