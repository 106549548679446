import {useHistory, useLocation} from 'react-router-dom'
import {useDrawerSections} from '../../../hooks/useDrawerSections'
import {CARD_DRAWER_SECTION_KEY} from '../../../navigation/RouteConstants'
import {Grid} from '@material-ui/core'
import DrawerMenuItemTitleComponent from '../../Common/Drawer/DrawerMenuItemTitle/DrawerMenuItemTitleComponent'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import DrawerMenuItemButtonComponent from '../../Common/Drawer/DrawerMenuItemButton/DrawerMenuItemButtonComponent'
import ResetPinDrawerMenuItemComponent from '../../Drawer/ResetPinDrawerMenuItem/ResetPinDrawerMenuItemComponent'
import React from 'react'

export default function CardDrawerMenuItemsComponent() {
  const history: any = useHistory();
  const location = useLocation();
  const drawerSections = useDrawerSections();

  const navigateToPage = (route: string) => {
    history.push(route)
  }

  const cardDrawerSection = drawerSections[CARD_DRAWER_SECTION_KEY];

  return (
    <Grid>
      <DrawerMenuItemTitleComponent
        key={cardDrawerSection.name}
        title={cardDrawerSection.name}
        icon={<FontAwesomeIcon icon={cardDrawerSection.icon}/>}
      />
      {
        cardDrawerSection.routes.map((route) =>
          <DrawerMenuItemButtonComponent
            name={route.description}
            key={route.description}
            selected={location.pathname === route.path}
            onClick={() => navigateToPage(route.path)}
          />
        )
      }
      {
        <ResetPinDrawerMenuItemComponent/>
      }
    </Grid>
  )
}