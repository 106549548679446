import React from 'react'
import { Grid } from '@material-ui/core'
import LandingHeaderComponent from '../../../components/Landing/LandingHeader/LandingHeaderComponent'
import LandingDescriptionComponent from '../../../components/Landing/LandingDescription/LandingDescriptionComponent'
import RouteToActivateCardButton from '../../../components/Card/RouteToActivateCardButton/RouteToActivateCardButton'
import { BaseLandingLayoutStyles } from './BaseLandingLayoutStyles'
import { ClassNameMap } from '@material-ui/styles'
import {useProgramContent} from '../../../hooks/useProgramContent'
import {RootStateOrAny} from 'react-redux'

export default function BaseLandingLayout() {
  const styles: ClassNameMap = BaseLandingLayoutStyles()
  const programContent: RootStateOrAny = useProgramContent();

  return (
    <Grid
      container
      className={styles.content}
      direction={'row'}
      justify={'center'}
      alignItems={'flex-start'}
    >
      <Grid
        item
        sm={12} md={6} lg={6}
        className={styles.wrapper}
      >
        <Grid
          item
        >
          <LandingHeaderComponent />
        </Grid>

        <Grid
          item
          className={styles.items}
        >
          <LandingDescriptionComponent />
        </Grid>

        
        
        <Grid
          item
          className={styles.items}
          style={programContent?.appBar?.title === "Mazda Reimbursement Card" ? {display: 'none'} : {}}
        >
           <RouteToActivateCardButton />
          </Grid>
        
      </Grid>
    </Grid>
  )
}
