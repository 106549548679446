import React, {ReactNode} from 'react'
import {Dialog, TextField, Typography} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {ResponsiveDialogComponentStyles} from './ResponsiveDialogComponentStyles'
import {ClassNameMap} from '@material-ui/styles'
import {useIsMobile} from '../../../hooks/useIsMobile'

interface ResponsiveDialogComponentProps {
  openDialog: boolean
  toggleDialog: () => void
  title: string
  children: ReactNode
  enableMobileFullScreen?: boolean
  testId?: string
}

export default function ResponsiveDialogComponent(props: ResponsiveDialogComponentProps) {
  const styles: ClassNameMap = ResponsiveDialogComponentStyles()
  const isMobile: boolean = useIsMobile()

  return (
    <Dialog
      fullScreen={props.enableMobileFullScreen ? isMobile : false}
      data-testid={props.testId ?? ''}
      open={props.openDialog}
      onClose={props.toggleDialog}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      classes={{
        paper: styles.dialogContainer,
      }}
    >
     
      <Typography className={styles.title}>{props.title}</Typography>

      <IconButton aria-label="close" className={styles.closeButton} onClick={props.toggleDialog}>
        <FontAwesomeIcon icon={faTimes} />
      </IconButton>

      <>
        {props.children}
      </>
    </Dialog>
  )
}