import {ProgramTheme} from '../../lib'

export const essentialThemeTemplate = (programTheme: ProgramTheme) => ({
  components: {
    root: {
      color: programTheme.colors.base.contrast,
      backgroundColor: programTheme.colors.base.main
    },
    appBar: {
      backgroundColor: programTheme.colors.runner.main,
      menuTabsColor: programTheme.colors.runner.contrast,
      menuTabsIndicatorColor: programTheme.colors.primary.main,
    },
    drawer: {
      backgroundColor: programTheme.colors.secondary.main,
      color: programTheme.colors.secondary.contrast,
      menuToggleColor: programTheme.colors.runner.contrast,
      menuButtonTitleColor: programTheme.colors.secondary.contrast,
      menuButtonTitleIconColor: programTheme.colors.secondary.contrast,
      menuButtonItemHoverColor: programTheme.colors.secondary.contrast,
      dividerBackgroundColor: programTheme.colors.secondary.contrast,
    },
    button: {
      borderRadius: programTheme.shapes.button.border_radius,
      primaryBackgroundColor: programTheme.colors.primary.main,
      primaryColor: programTheme.colors.primary.contrast,
      secondaryBackgroundColor: programTheme.colors.secondary.main,
      secondaryColor: programTheme.colors.secondary.contrast,
    },
    paper: {
      borderRadius: programTheme.shapes.container.border_radius,
    },
    loading: {
      color: programTheme.colors.base.main
    },
    table: {
      backgroundColor: programTheme.colors.paper.main,
      titleColor: programTheme.colors.paper.contrast, 
      headBackgroundColor: programTheme.colors.paper.main,
      headColor: programTheme.colors.paper.contrast,
      rowColor: programTheme.colors.paper.contrast,
      rowHoverBackgroundColor: programTheme.colors.paper.contrast,
      rowHoverColor: programTheme.colors.paper.main,
    },
    pageTitle: {
      color: programTheme.colors.base.contrast
    },
    pageTitleWithIcon: {
      color: programTheme.colors.base.contrast
    },
    cardInfo: {
      balanceColor: programTheme.colors.primary.main
    },
    modal: {
      closeButtonIconColor: programTheme.colors.base.contrast,
      confirmationColor: programTheme.colors.primary.main,
      confirmationIconColor: programTheme.colors.secondary.main,
    },
    form: {
      color: programTheme.colors.base.contrast,
      textFieldColor: programTheme.colors.base.contrast,
      textFieldDisabledColor: programTheme.colors.base.contrast,
      textFieldFocusColor: programTheme.colors.base.contrast,
      textFieldFocusBorderColor: programTheme.colors.base.contrast,
    },
    footer: {
      topBackgroundColor: programTheme.colors.runner.main,
      topColor: programTheme.colors.runner.contrast,
      topHoverColor: programTheme.colors.runner.contrast,
      bottomBackgroundColor: programTheme.colors.legal.main,
      bottomColor: programTheme.colors.legal.contrast,
    }
  },
  selectors: {
      darken: programTheme.colors.selector.darken_coefficient
  },
  typography: {
    h1: {
      fontFamily: programTheme.fonts.family.heading,
      fontSize: programTheme.fonts.size.xl,
      fontWeight: programTheme.fonts.weight.bold,
    },
    h2: {
      fontFamily: programTheme.fonts.family.heading,
      fontSize: programTheme.fonts.size.large,
      fontWeight: programTheme.fonts.weight.bold,
    },
    h3: {
      fontFamily: programTheme.fonts.family.heading,
      fontSize: programTheme.fonts.size.medium,
      fontWeight: programTheme.fonts.weight.bold,
    },
    h4: {
      fontFamily: programTheme.fonts.family.heading,
      fontSize: programTheme.fonts.size.small,
      fontWeight: programTheme.fonts.weight.bold,
    },
    h5: {
      fontFamily: programTheme.fonts.family.heading,
      fontSize: programTheme.fonts.size.xs,
      fontWeight: programTheme.fonts.weight.bold,
    },
    subtitle1: {
      fontFamily: programTheme.fonts.family.body,
      fontSize: programTheme.fonts.size.medium,
      fontWeight: programTheme.fonts.weight.normal,
    },
    body1: {
      fontFamily: programTheme.fonts.family.body,
      fontSize: programTheme.fonts.size.small,
      fontWeight: programTheme.fonts.weight.normal,
    },
    body2: {
      fontFamily: programTheme.fonts.family.body,
      fontSize: programTheme.fonts.size.xs,
      fontWeight: programTheme.fonts.weight.normal,
    },
    button: {
      fontFamily: programTheme.fonts.family.heading,
      fontSize: programTheme.fonts.size.medium,
      fontWeight: programTheme.fonts.weight.bold,
    },
  }
})