import React from 'react'
import { Typography } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'
import { LandingHeaderComponentStyles } from './LandingHeaderComponentStyles'
import {useProgramContent} from '../../../hooks/useProgramContent'
import {RootStateOrAny} from 'react-redux'

export default function LandingHeaderComponent() {
  const styles: ClassNameMap<"root"> = LandingHeaderComponentStyles()
  const programContent: RootStateOrAny = useProgramContent();

  return (
    <Typography
      id="pages-landing-title"
      align='center'
      gutterBottom
      className={styles.root}
      data-testid={'landing-header'}
    >
      {programContent?.pages?.landing?.title || "Oops, something went wrong."}
    </Typography>
  )
}