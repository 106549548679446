import {AxiosResponse} from 'axios'

export default class ApiResponseService {
  public static responseWasSuccessful(response: AxiosResponse) {
    try {
      return typeof response !== 'undefined' && Number(response?.status) < 400
    } catch (exception) {
      return false
    }
  }
}
