import React from 'react'
import {Typography} from '@material-ui/core'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import ResponsiveConfirmationDialogComponent
  from '../../Common/ResponsiveConfirmationDialog/ResponsiveConfirmationDialogComponent'
import { ResetPinConfirmationDialogComponentStyles } from './ResetPinConfirmationDialogComponentStyles'
import {ClassNameMap} from '@material-ui/styles'

interface ResetPinConfirmationDialogProps {
  openConfirmationDialog: boolean
  toggleResetPinConfirmationDialog: () => void
}

export default function ResetPinConfirmationDialogComponent({ openConfirmationDialog, toggleResetPinConfirmationDialog }: ResetPinConfirmationDialogProps) {
  const styles: ClassNameMap = ResetPinConfirmationDialogComponentStyles()

  return (
    <ResponsiveConfirmationDialogComponent
      openDialog={openConfirmationDialog}
      toggleDialog={toggleResetPinConfirmationDialog}
      title={'Reset PIN'}
      confirmationIcon={faCheckCircle}
      statusMessage={'Reset PIN Confirmed'}
    >
      <Typography
          data-testid={'pin-reset-success-message'}
          className={styles.body}>
        New card PIN will be active in 2 hours.
      </Typography>
    </ResponsiveConfirmationDialogComponent>
  )
}