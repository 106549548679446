import React from 'react'
import { useHistory } from 'react-router-dom'
import { RouteConstants } from '../../../navigation/RouteConstants'
import PrimaryButtonComponent from '../../Common/PrimaryButton/PrimaryButtonComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

export default function RouteToActivateCardButton() {
  const history: any = useHistory()

  return (
    <PrimaryButtonComponent
      onClick={() => history.push(RouteConstants.ACTIVATE_CARD_ROUTE)}
      text={'Activate Card'}
      icon={<FontAwesomeIcon icon={faCheckCircle} />}
    />
  )
}