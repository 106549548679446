import {Request} from 'miragejs'
import MockedJWTInteractor, {TOKEN_TYPES} from './MockedJWTInteractor'
import MockedResponse from './MockedResponse'

export default class MockedMiddleware {
  public static cardEssentialRequestMiddleware(request: Request) {
    if (!MockedMiddleware.requestHasAuthorizationHeader(request)) {
      return MockedResponse.getUnauthorizedResponse()
    }
    if (
      !MockedMiddleware.requestHasCorrectAuthorizationHeaderAccessToken(
        request,
        TOKEN_TYPES.CARD_AUTHENTICATED_AKA_VERIFIED,
      ) &&
      !MockedMiddleware.requestHasCorrectAuthorizationHeaderAccessToken(
        request,
        TOKEN_TYPES.USER_AUTHENTICATED,
      )
    ) {
      return MockedResponse.getUnauthenticatedResponse()
    }
  }

  public static requestHasCorrectAuthorizationHeaderAccessToken(
    request: Request,
    tokenType: TOKEN_TYPES,
  ) {
    const accessToken = request.requestHeaders['Authorization'].replace(
      'Bearer ',
      '',
    )
    try {
      return MockedJWTInteractor.verifyToken(accessToken, tokenType)
    } catch (exception) {
      return false
    }
  }

  public static requestHasAuthorizationHeader(request: Request) {
    return MockedMiddleware.requestHasHeader(request, 'Authorization')
  }

  public static requestHasHeader(request: Request, name: string) {
    try {
      return (
        !(typeof request.requestHeaders[name] === 'undefined') &&
        request.requestHeaders[name] !== ''
      )
    } catch (exception) {
      console.warn(exception)
      return false
    }
  }
}
