import React from 'react'
import {Grid} from '@material-ui/core'
import PageDescriptionComponent from '../../Common/PageDescription/PageDescriptionComponent'
import {ClassNameMap} from '@material-ui/styles'
import {ContactUsDescriptionComponentStyles} from './ContactUsDescriptionComponentStyles'
import {useProgramContent} from '../../../hooks/useProgramContent'
import {RootStateOrAny} from 'react-redux'

export default function ContactUsDescriptionComponent() {
  const programContent: RootStateOrAny = useProgramContent();
  const styles: ClassNameMap = ContactUsDescriptionComponentStyles();

  return (
    <>
      { programContent?.pages?.contactUs?.rows.map((value: string, index: number) => (
        <Grid
          container
          direction={'row'}
          justify={'center'}
          key={`pages-contactUs-rows-${index}`}
        >

          <Grid item xs={12} sm={10} md={8} lg={8} className={styles.item}>
            <PageDescriptionComponent id={`pages-contactUs-rows-${index}`}>
              {value}
            </PageDescriptionComponent>
          </Grid>

        </Grid>
      )) }

      { programContent?.pages?.contactUs?.columns.map((column: {name: string, value: string}, index: number) => (
        <Grid
          container
          direction={'row'}
          justify={'center'}
          key={`pages-contactUs-columns-${index}`}
          id={`pages-contactUs-columns-${index}`}
        >

          <Grid item xs={6} sm={5} md={4} lg={4} className={styles.item}>
            <PageDescriptionComponent>
              {column.name}
            </PageDescriptionComponent>
          </Grid>

          <Grid item xs={6} sm={5} md={4} lg={4} className={styles.item}>
            <PageDescriptionComponent>
              {column.value}
            </PageDescriptionComponent>
          </Grid>

        </Grid>
      )) }
    </>
  )
}