import React from 'react'
import { ListItem, ListItemText } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'
import { DrawerMenuItemButtonComponentStyles } from './DrawerMenuItemButtonComponentStyles'

interface DrawerMenuItemButtonProps {
  name: string
  onClick?: any
  selected?: boolean
}

export default function DrawerMenuItemButtonComponent({ name, onClick, selected }: DrawerMenuItemButtonProps) {
  const styles: ClassNameMap = DrawerMenuItemButtonComponentStyles()

  return (
    <ListItem
      button
      onClick={onClick ?? null}
      className={styles.root}
      selected={selected}
    >
      <ListItemText disableTypography className={styles.drawerItem} inset>
        {name}
      </ListItemText>
    </ListItem>
  )
}