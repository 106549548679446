import React, { ReactNode } from 'react'
import {ClassNameMap} from '@material-ui/styles'
import {RootLayoutStyles} from './RootLayoutStyles'
import HelmetComponent from '../components/Common/Helmet/HelmetComponent'
import {DrawerContextProvider} from '../hooks/DrawerContext'

interface RootLayoutProps {
  children: ReactNode
}

export default function RootLayout({ children }: RootLayoutProps) {
  const styles: ClassNameMap = RootLayoutStyles();

  return (
    <div className={styles.root} id="root-layout">
      <HelmetComponent />
      <DrawerContextProvider>
        {children}
      </DrawerContextProvider>
    </div>
  )
}
