import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import { darken } from '@material-ui/core/styles/colorManipulator'

export const DrawerMenuItemButtonComponentStyles = makeStyles((theme: Theme) => ({
    root: {
      paddingTop: '0.25rem',
      paddingBottom: '0.25rem',
      '&:hover': {
        backgroundColor: darken(theme.components.drawer.backgroundColor, theme.selectors.darken),
      }
    },
    drawerItem: {
      ...theme.typography.body1,
  },
}))