import React from 'react'
import {Typography} from '@material-ui/core'
import {ClassNameMap} from '@material-ui/styles'
import {FooterTitleComponentStyles} from './FooterTitleComponentStyles'

interface FooterTitleProps {
  title: string
}

export default function FooterTitleComponent({ title }: FooterTitleProps) {
  const styles: ClassNameMap = FooterTitleComponentStyles();
  return <Typography className={styles.root}>{title}</Typography>
}