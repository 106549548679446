import React from 'react';
import { RouteConstants } from '../../../navigation/RouteConstants'
import VerifyCardForm from '../../../components/Forms/VerifyCardForm/VerifyCardFormComponent'
import { Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { BaseActivateCardLayoutStyles } from './BaseActivateCardLayoutStyles'
import { ClassNameMap } from '@material-ui/styles'
import CardActivationInteractor from "../../../lib/useCases/CardActivationInteractor";
import SnackbarUtils from "../../../modals/SnackbarNotificationModel";

export default function BaseActivateCardLayout() {
  const styles: ClassNameMap = BaseActivateCardLayoutStyles()
  const activationAction = () => CardActivationInteractor.activateCard()

  return (
    <Grid
      container 
      direction={'row'}
      justify={'center'}
      alignItems={'flex-start'}
    >
      <Grid 
          item
          sm={12} md={6} lg={6}
          className={styles.wrapper}
        >
          <VerifyCardForm
            pageTitle={'Activate Card'}
            route={RouteConstants.CARD_INFO_ROUTE}
            buttonText={'Activate Card'}
            icon={<FontAwesomeIcon icon={faCheckCircle} />}
            errorMessage={'Error! Please verify the information you have entered.'}
            cardActivationStatusAction={async (cardStatus: number) => {
              try {
                if (cardStatus !== 1) {
                  await activationAction();
                  SnackbarUtils.success('Card activation successful! Card will be available for use within two hours.')
                } else {
                  SnackbarUtils.success('Card already active.')
                }
              } catch (e) {
                SnackbarUtils.error('Error! Please verify the information you have entered.')
              }
            }}
          />
        </Grid>
    </Grid>
  )
}