import React from 'react'
import ResponsiveDialogComponent from '../../Common/ResponsiveDialog/ResponsiveDialogComponent'
import ResetPinFormComponent from '../../Forms/ResetPinForm/ResetPinFormComponent'

interface ResetPinDialogProps {
  openDialog: boolean
  toggleResetPinDialog: () => void
  toggleResetPinConfirmationDialog: () => void
}

export default function ResetPinDialogComponent({ openDialog, toggleResetPinDialog, toggleResetPinConfirmationDialog }: ResetPinDialogProps) {
  return (
    <ResponsiveDialogComponent openDialog={openDialog} toggleDialog={toggleResetPinDialog} title={'Reset PIN'} enableMobileFullScreen={true} testId={'reset-pin-dialog-confirmation'}>
      <ResetPinFormComponent toggleResetPinDialog={toggleResetPinDialog} toggleResetPinConfirmationDialog={toggleResetPinConfirmationDialog} />
    </ResponsiveDialogComponent>
  )
}