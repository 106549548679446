import React, { ReactNode } from 'react'
import { ListItem, ListItemIcon, Typography } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'
import { DrawerMenuItemTitleComponentStyles } from './DrawerMenuItemTitleComponentStyles'

interface DrawerMenuItemTitleProps {
  title: string
  icon: ReactNode
  onClick?: any
}

export default function DrawerMenuItemTitleComponent({ title, icon, onClick }: DrawerMenuItemTitleProps) {
  const styles: ClassNameMap = DrawerMenuItemTitleComponentStyles()
  
  return (
    <ListItem
      button
      onClick={onClick}
      className={styles.root}
    >
        <ListItemIcon className={styles.icon}>
          {icon}
        </ListItemIcon>

      <Typography className={styles.drawerTitle} noWrap>
        {title}
      </Typography>
    </ListItem>
  )
}