import React, {ReactNode} from 'react'
import {Dialog, Grid, Typography} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes, faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import {ResponsiveConfirmationDialogComponentStyles} from './ResponsiveConfirmationDialogComponentStyles'
import {IconProp} from '@fortawesome/fontawesome-svg-core'
import PrimaryButtonComponent from '../PrimaryButton/PrimaryButtonComponent'
import {ClassNameMap} from '@material-ui/styles'

interface ResponsiveConfirmationDialogProps {
    openDialog: boolean
    toggleDialog: () => void
    title: string
    children: ReactNode
    confirmationIcon: IconProp
    statusMessage: string
}

export default function ResponsiveConfirmationDialogComponent(props: ResponsiveConfirmationDialogProps) {
    const styles: ClassNameMap = ResponsiveConfirmationDialogComponentStyles()

    return (
        <Dialog
            open={props.openDialog}
            onClose={props.toggleDialog}

            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            classes={{
                paper: styles.dialogContainer,
            }}
        >
            <Typography className={styles.title}>{props.title}</Typography>
            <IconButton aria-label="close" className={styles.closeButton} onClick={props.toggleDialog}>
                <FontAwesomeIcon icon={faTimes}/>
            </IconButton>
            <Grid
                container
                direction={'column'}
                justify={'center'}
                alignItems={'center'}
            >
                <Grid item className={styles.icon}>
                    <FontAwesomeIcon icon={props.confirmationIcon} size='4x'/>
                </Grid>

                <Grid item>
                    <Typography className={styles.subtitle}>{props.statusMessage}</Typography>
                </Grid>

                <Grid item>
                    <Typography className={styles.body}>
                        {props.children}
                    </Typography>
                </Grid>
                <PrimaryButtonComponent onClick={props.toggleDialog} text={'Close'}
                                        icon={<FontAwesomeIcon icon={faTimesCircle}/>}/>
            </Grid>
        </Dialog>
    )
}