import React from 'react'
import { Grid, Typography, IconButton } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'
import { DrawerProfileComponentStyles } from './DrawerProfileComponentStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'

interface DrawerProfileProps {
  username: string
}

export default function DrawerProfileComponent({ username }: DrawerProfileProps) {
  const styles: ClassNameMap = DrawerProfileComponentStyles()

  return (
      <Grid
        container
        direction={'column'}
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
          >
            <FontAwesomeIcon className={styles.avatar} icon={faUserCircle} />
          </IconButton>
        </Grid>

        <Grid item>
          <Typography className={styles.username}>{username}</Typography>
        </Grid>

        <Grid item>
          <Typography className={styles.roleType}>Cardholder</Typography>
        </Grid>
      </Grid>
  )
}