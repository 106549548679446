import React from 'react'
import { DebugBarLayoutStyles } from './DebugBarLayoutStyles'
import { ClassNameMap } from '@material-ui/styles'
import ProgramSelector from '../../components/Common/ProgramSelector/ProgramSelector'
import { Grid, Typography } from '@material-ui/core'

export default function DebugBarLayout() {
  const styles: ClassNameMap = DebugBarLayoutStyles()

  return (
    <Grid
      container
      className={styles.wrapper}
      direction={'column'}
      justify={'space-around'}
    >
      <Typography
        className={styles.title}
      >
        Development Debug Bar
      </Typography>
      <ProgramSelector />
    </Grid>
  )
}
