import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import { darken } from '@material-ui/core/styles/colorManipulator'

export const PrimaryButtonComponentStyles = makeStyles((theme: Theme) => ({
  root: {
    textTransform: 'capitalize',
    width: '100%',
    maxWidth: '18rem',
    marginTop: '1em',
    borderRadius: theme.components.button.borderRadius,
    backgroundColor: theme.components.button.primaryBackgroundColor,
    color: theme.components.button.primaryColor,
    "&:hover": {
      backgroundColor: darken(theme.components.button.primaryBackgroundColor, theme.selectors.darken),
    }
  },
}))
