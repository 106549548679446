import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import {useMobileSmQuery} from "../../../hooks/useIsMobile";

export const BaseCardInfoLayoutStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(4),
  },
  breadcrumb: {
    paddingBottom: theme.spacing(4),
  },
  content: {
    textAlign: 'center',
  },
  item: {
    paddingTop: '1em',
  },
  subtitleHeader: {
    ...theme.typography.h1,
    // [useMobileSmQuery()]: {
    //   ...theme.typography.h2
    // }
  },
}))