import React from 'react'
import clsx from 'clsx'
import { AppBar, IconButton, Toolbar } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import BaseAppBarLayout from '../../layouts/HeaderLayouts/AppBarLayouts/BaseAppBarLayout'
import { AppBarComponentStyles } from './AppBarComponentStyles'
import { ClassNameMap } from '@material-ui/styles'
import { DrawerContextInterface, useDrawerContext } from '../../hooks/DrawerContext'
import { useIsAuthenticated } from '../../hooks/useIsAuthenticated'
import { useIsMobile } from '../../hooks/useIsMobile'

export default function AppBarComponent() {
  const styles: ClassNameMap = AppBarComponentStyles()

  const isAuthenticated: boolean = useIsAuthenticated()
  const isMobile: boolean = useIsMobile()
  const { drawerIsOpen, toggleDrawerIsOpen }: DrawerContextInterface = useDrawerContext()

  return (
    <AppBar
      id="appbar"
      position="sticky"
      className={clsx(styles.appBar, {
        [styles.appBarShift]: drawerIsOpen && !isMobile,
      })}
    >
      <Toolbar
        id="toolbar"
        className={styles.toolbar}
        disableGutters
      >
        {isAuthenticated && !isMobile && (
          <IconButton
            id="appbar-drawer-toggle"
            aria-label="open drawer"
            onClick={toggleDrawerIsOpen}
            edge="start"
            className={clsx(styles.menuButton)}
          >
            {drawerIsOpen ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faBars} />}
          </IconButton>
        )}

        <BaseAppBarLayout />

      </Toolbar>
    </AppBar>
  )
}