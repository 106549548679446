export class ValidateInput {
    public static stringIsNumberOfDigits(string: string, numberOfDigits: number) {
        let regexString = `\\b\\d{${numberOfDigits}}\\b`;
        const regex = new RegExp(regexString, 'g');
        return regex.test(string);
    }

    public static stringIsValidAlphaNumericNumberOfDigits(string: string, numberOfDigits: number){
        let regexString = `\\b\[0-9a-zA-Z]{${numberOfDigits}}\\b`;
        const regex = new RegExp(regexString, 'g');
        return regex.test(string);
    }
}