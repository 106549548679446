import React, { ReactNode } from 'react'
import { Typography } from '@material-ui/core'
import {ClassNameMap} from '@material-ui/styles'
import {TopFooterDescriptionComponentStyles} from './TopFooterDescriptionComponentStyles'

interface FooterDescriptionProps {
  children: ReactNode
  id?: string
}

export default function TopFooterDescriptionComponent({ children, id }: FooterDescriptionProps) {
  const styles: ClassNameMap = TopFooterDescriptionComponentStyles();
  return (
    <Typography className={styles.root} id={id}>{children}</Typography>
  )
}