import ApiRequestService from '../services/ApiRequestService'

export default class ProgramIdentificationInteractor {
  public static async getProgramInfo(): Promise<any> {
    return await ApiRequestService.get('/programs/info')
  }
  public static async getProgramByUrl(url: string): Promise<any> {
    return await ApiRequestService.get('/programs/lookup?url=' + url)
  }
  public static async getPrograms(): Promise<any> {
    return await ApiRequestService.get('/programs/all')
  }
}
