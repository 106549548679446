import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'

export const drawerWidth: number = 240;

export const DrawerComponentStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      border: '0',
      backgroundColor: theme.components.drawer.backgroundColor
    }
  }),
);