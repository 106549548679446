export enum RouteConstants {
  HOME_KEY = 'home',
  DASHBOARD_KEY = 'dashboard',
  CARD_INFO_KEY = 'card/info',
  TRANSACTIONS_KEY = 'transactions',
  CHECK_BALANCE_KEY = 'checkBalance',
  ACTIVATE_CARD_KEY = 'activateCard',
  CHECK_CARD_BALANCE_KEY = 'checkCardBalance',
  CONTACT_US_KEY = 'contactUs',
  AWARDS_KEY = 'awards',
  RESET_PIN_KEY = 'reset',
  DRAWER_TOGGLE_KEY = 'drawerToggle',
  HELP_KEY = 'help',

  HOME_DESCRIPTION = 'Home',
  DASHBOARD_DESCRIPTION = 'Dashboard',
  CARD_INFO_DESCRIPTION = 'Card',
  TRANSACTION_HISTORY_DESCRIPTION = 'Transaction History',
  CHECK_BALANCE_DESCRIPTION = 'Check Balance',
  ACTIVATE_CARD_DESCRIPTION = 'Activate Card',
  CHECK_CARD_BALANCE_DESCRIPTION = 'Check Balance',
  CONTACT_US_DESCRIPTION = 'Contact Us',
  AWARDS_DESCRIPTION = 'Awards',
  RESET_PIN_DESCRIPTION = 'Reset Pin',
  DRAWER_TOGGLE_DESCRIPTION = 'Toggle Drawer',
  HELP_DESCRIPTION = 'Help',

  HOME_ROUTE = '/',
  DASHBOARD_ROUTE = '/',
  CARD_INFO_ROUTE = '/card/info',
  ACTIVATE_CARD_ROUTE = '/card/activate',
  CHECK_CARD_BALANCE_ROUTE = '/card/balance',
  CONTACT_US_ROUTE = '/contact',
  AWARDS_ROUTE = '/awards',
  RESET_PIN_ROUTE = '/card/pin',
  HELP_ROUTE = '/help',
}

export const CARD_DRAWER_SECTION_KEY = "card";
