import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import { useHeroImage } from '../../../hooks/useHeroImage'

export const BaseLandingLayoutStyles = makeStyles((theme: Theme) => ({
  content: {
    minHeight: '100vh',
    height: '100%',
    textAlign: 'center',
    background: `linear-gradient(rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.25)),url(${useHeroImage()})`, //refactor so it pull in program info
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  wrapper: {
    padding: '100px 30px',
    textAlign: 'center',
  },
  items: {
    paddingTop: '1em',
  }
}))