import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {useMobileSmQuery} from "../../hooks/useIsMobile";

export const BaseDrawerLayoutStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            height: '100vh',
            backgroundColor: theme.components.drawer.backgroundColor,
            color: theme.components.drawer.color,
            textAlign: 'center',
            borderRight: '0',
            [useMobileSmQuery()]: {
                marginBottom: theme.spacing(2),
            }
        }
    }),
);