import {Response} from 'miragejs'

export default class MockedResponse {
  private static getResponse(code: number, message: string = '', data: any = {}) {
    return new Response(code, {}, {message, data})
  }

  /**
   * Successful message provides a 200
   * @param message
   * @param data
   */
  public static getSuccessfulResponse(message: string, data: any) {
    return MockedResponse.getResponse(200, message, data);
  }
  /*
    401 Unauthorized: If the request already included Authorization credentials, then the 401 response indicates that
    authorization has been refused for those credentials
    */
  public static getUnauthenticatedResponse() {
    return MockedResponse.getResponse(401)
  }
  /*
    403 Forbidden: The server understood the request, but is refusing to fulfill it.
    */
  public static getUnauthorizedResponse() {
    return MockedResponse.getResponse(403)
  }

  /**
   * 422 states a data value or type is invalid.
   * @param reason
   */
  public static getUnprocessableEntityResponse(reason: string) {
    return MockedResponse.getResponse(422, reason)
  }

  /**
   * 400 states a data value or type is invalid.
   * @param reason
   */
  public static getBadRequestResponse(reason: string) {
    return MockedResponse.getResponse(400, reason)
  }
}
