import React from 'react'
import BottomFooterDescriptionComponent
  from '../../Common/Footer/BottomFooterDescription/BottomFooterDescriptionComponent'
import {useProgramContent} from '../../../hooks/useProgramContent'
import {RootStateOrAny} from 'react-redux'

export default function BankDisclaimerComponent() {
  const programContent: RootStateOrAny = useProgramContent();


  return (
    <BottomFooterDescriptionComponent id={'footer-disclaimerText'}>
      {programContent?.footer?.disclaimerText}
    </BottomFooterDescriptionComponent>
  )
}