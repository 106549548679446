import React, {ReactNode} from 'react'
import clsx from 'clsx'
import { Grid } from '@material-ui/core'
import { BaseBodyLayoutStyles } from './BaseBodyLayoutStyles'
import { ClassNameMap } from '@material-ui/styles'
import {useDrawerContext} from '../../hooks/DrawerContext'
import {useIsMobile} from '../../hooks/useIsMobile'

interface BaseBodyLayoutProps {
  children: ReactNode
}

export default function BaseBodyLayout({children}: BaseBodyLayoutProps) {
  const styles: ClassNameMap = BaseBodyLayoutStyles()
  const { drawerIsOpen } = useDrawerContext()
  const isMobile = useIsMobile()

  return (
    <>
      <main
        id="base-body-layout"
        className={clsx(styles.mainContent, {
          [styles.mainContentShift]: drawerIsOpen && !isMobile,
        })}
        >
        <Grid
          container
          className={styles.content}
          direction={'row'}
          justify={'center'}
          alignItems={'flex-start'}
        >
          {children}
        </Grid>
      </main>
    </>
  )
}
