// src/server.js
import {Factory, Model, Request, Server as MirageJSServer, createServer} from 'miragejs'
import MockedJWTInteractor, {TOKEN_TYPES} from './MockedJWTInteractor'
import MockedMiddleware from './MockedMiddleware'
import MockedResponse from './MockedResponse'
import {
    LOCALHOST_URL,
    SAMPLE_CARD_PHONE, SAMPLE_INACTIVE_CARD_NUMBER, SAMPLE_INCORRECT_PHONE_LAST_FOUR,
    SAMPLE_INCORRECT_SSN_LAST_FOUR_NUMBER, SAMPLE_INCORRECT_VIN_LAST_SIX,
    SAMPLE_INVALID_CARD_NUMBER,
    SAMPLE_SSN_CARD_NUMBER,
    SAMPLE_VIN_CARD_NUMBER
} from './LocalDevelopmentConstants'
import {cardModel, programModel, transactionModel, userModel} from "./models";

const standardTimeForRequestToComplete = {timing: 500}

// @ts-ignore
if (window.Cypress) {
    let methods = ["get", "put", "patch", "post", "delete"]

    createServer({
        environment: "test",
        routes() {
            for (const domain of [LOCALHOST_URL]) {
                for (const method of methods) {
                    // @ts-ignore
                    this[method](`${domain}*`, async (schema, request) => {
                        // @ts-ignore
                        let [status, headers, body] = await window.handleFromCypress(
                            request
                        )
                        // @ts-ignore
                        return new Response(status, headers, body)
                    })
                }
            }
        },
    })
}

export function makeServer({environment = 'development'} = {}) {
    console.log('Starting the MirageJS Server...')
    return new MirageJSServer({
        environment: environment,
        models: {
            user: Model,
            program: Model,
            card: Model,
            transaction: Model,
        },
        factories: {
            user: Factory.extend(userModel),
            program: Factory.extend(programModel),
            card: Factory.extend(cardModel),
            transaction: Factory.extend(transactionModel),
        },
        seeds(server) {
            server.create('program')
            server.create('user')
            server.create('card')
            server.createList('transaction', 50)
        },
        routes() {
            let cardNumber = '';
            this.get(
                '/programs/info',
                (schema: any, request: Request) => {
                    return {data: schema.programs.first()}
                },
                standardTimeForRequestToComplete,
            )

            this.post(
                '/cards/verification',
                (schema: any, request: Request) => {
                    const attrs = JSON.parse(request.requestBody)
                    console.log('request body', attrs)
                    cardNumber = attrs['card_number']
                    if (cardNumber === SAMPLE_SSN_CARD_NUMBER) {
                        return MockedResponse.getSuccessfulResponse(
                            'Card successfully validated',
                            {
                                validation_access_token: MockedJWTInteractor.createMockJWTToken(
                                    'Validation Token',
                                    TOKEN_TYPES.CARD_VALIDATED,
                                ),
                                validation_type: 'ssn',
                            },
                        )
                    }

                    if (cardNumber === SAMPLE_VIN_CARD_NUMBER) {
                        return MockedResponse.getSuccessfulResponse(
                            'Card successfully validated',
                            {
                                validation_access_token: MockedJWTInteractor.createMockJWTToken(
                                    'Validation Token',
                                    TOKEN_TYPES.CARD_VALIDATED,
                                ),
                                validation_type: 'vin',
                            },
                        )
                    }

                    if (cardNumber === SAMPLE_CARD_PHONE) {
                        return MockedResponse.getSuccessfulResponse(
                            'Card successfully validated',
                            {
                                validation_access_token: MockedJWTInteractor.createMockJWTToken(
                                    'Validation Token',
                                    TOKEN_TYPES.CARD_VALIDATED,
                                ),
                                validation_type: 'phone',
                            },
                        )
                    }

                    return MockedResponse.getSuccessfulResponse(
                        'Card successfully validated',
                        {
                            validation_access_token: MockedJWTInteractor.createMockJWTToken(
                                'Validation Token',
                                TOKEN_TYPES.CARD_VALIDATED,
                            ),
                            validation_type: 'none',
                        },
                    )
                },
                standardTimeForRequestToComplete,
            )

            this.post(
                '/cards/authentication',
                (schema: any, request: Request) => {
                    if (
                        !MockedMiddleware.requestHasCorrectAuthorizationHeaderAccessToken(
                            request,
                            TOKEN_TYPES.CARD_VALIDATED,
                        )
                    ) {
                        return MockedResponse.getUnauthenticatedResponse()
                    }
                    return MockedResponse.getSuccessfulResponse(
                        'Card successfully authenticated',
                        {
                            authentication_access_token: MockedJWTInteractor.createMockJWTToken(
                                'Authentication Token',
                                TOKEN_TYPES.CARD_AUTHENTICATED_AKA_VERIFIED,
                            ),
                            expired: false,
                            status: cardNumber === SAMPLE_INACTIVE_CARD_NUMBER ? 7 : 1,
                        },
                    )
                },
                standardTimeForRequestToComplete,
            )

            this.post(
                '/cards/authentication/vin',
                (schema: any, request: Request) => {
                    if (
                        !MockedMiddleware.requestHasCorrectAuthorizationHeaderAccessToken(
                            request,
                            TOKEN_TYPES.CARD_VALIDATED,
                        )
                    ) {
                        return MockedResponse.getUnauthenticatedResponse()
                    }
                    const attrs = JSON.parse(request.requestBody)
                    const vin = attrs.vin;
                    if(vin === SAMPLE_INCORRECT_VIN_LAST_SIX){
                        return MockedResponse.getBadRequestResponse('Invalid VIN')
                    }
                    return MockedResponse.getSuccessfulResponse(
                        'Card successfully authenticated',
                        {
                            authentication_access_token: MockedJWTInteractor.createMockJWTToken(
                                'Authentication Token',
                                TOKEN_TYPES.CARD_AUTHENTICATED_AKA_VERIFIED,
                            ),
                            expired: false,
                            status: 1,
                        },
                    )
                },
                standardTimeForRequestToComplete,
            )

            this.post(
                '/cards/authentication/identification',
                (schema: any, request: Request) => {
                    if (
                        !MockedMiddleware.requestHasCorrectAuthorizationHeaderAccessToken(
                            request,
                            TOKEN_TYPES.CARD_VALIDATED,
                        )
                    ) {
                        return MockedResponse.getUnauthenticatedResponse()
                    }
                    const attrs = JSON.parse(request.requestBody)
                    const last_four_ssn = attrs.identification;
                    if(last_four_ssn === SAMPLE_INCORRECT_SSN_LAST_FOUR_NUMBER){
                        return MockedResponse.getBadRequestResponse('Invalid SSN')
                    }
                    return MockedResponse.getSuccessfulResponse(
                        'Card successfully authenticated',
                        {
                            authentication_access_token: MockedJWTInteractor.createMockJWTToken(
                                'Authentication Token',
                                TOKEN_TYPES.CARD_AUTHENTICATED_AKA_VERIFIED,
                            ),
                            expired: false,
                            status: 1,
                        },
                    )
                },
                standardTimeForRequestToComplete,
            )

            this.post(
                '/cards/authentication/phone',
                (schema: any, request: Request) => {
                    if (
                        !MockedMiddleware.requestHasCorrectAuthorizationHeaderAccessToken(
                            request,
                            TOKEN_TYPES.CARD_VALIDATED,
                        )
                    ) {
                        return MockedResponse.getUnauthenticatedResponse()
                    }
                    const attrs = JSON.parse(request.requestBody)
                    const last_four_phone = attrs.phone;
                    if(last_four_phone === SAMPLE_INCORRECT_PHONE_LAST_FOUR){
                        return MockedResponse.getBadRequestResponse('Invalid Phone')
                    }
                    return MockedResponse.getSuccessfulResponse(
                        'Card successfully authenticated',
                        {
                            authentication_access_token: MockedJWTInteractor.createMockJWTToken(
                                'Authentication Token',
                                TOKEN_TYPES.CARD_AUTHENTICATED_AKA_VERIFIED,
                            ),
                            expired: false,
                            status: 1,
                        },
                    )
                },
                standardTimeForRequestToComplete,
            )

            // CARDS ENDPOINTS
            this.post(
                '/cards/activate',
                (schema, request: Request) => {
                    const unauthorizedResponse = MockedMiddleware.cardEssentialRequestMiddleware(
                        request,
                    )
                    if (unauthorizedResponse) {
                        return unauthorizedResponse
                    }
                    if (cardNumber === SAMPLE_INVALID_CARD_NUMBER) {
                        return MockedResponse.getUnauthenticatedResponse()
                    }
                    return MockedResponse.getSuccessfulResponse(
                        'Card successfully activated',
                        {},
                    )
                },
                standardTimeForRequestToComplete,
            )

            this.get(
                '/cards/info',
                (schema: any, request: Request) => {
                    const unauthorizedResponse = MockedMiddleware.cardEssentialRequestMiddleware(
                        request,
                    )
                    if (unauthorizedResponse) {
                        return unauthorizedResponse
                    }
                    return MockedResponse.getSuccessfulResponse('Card Information', schema.cards.first())
                },
                standardTimeForRequestToComplete,
            )

            this.put(
                '/cards/pin/reset',
                (schema, request: Request) => {
                    const unauthorizedResponse = MockedMiddleware.cardEssentialRequestMiddleware(
                        request,
                    )
                    if (unauthorizedResponse) {
                        return unauthorizedResponse
                    }
                    const attrs = JSON.parse(request.requestBody)
                    if (!attrs.pin || attrs.pin.length < 4) {
                        return MockedResponse.getUnprocessableEntityResponse('Invalid pin')
                    }
                    return MockedResponse.getSuccessfulResponse('Card Pin Reset', {})
                },
                standardTimeForRequestToComplete,
            )

            this.get(
                '/cards/transactions',
                (schema: any, request: Request) => {
                    const unauthorizedResponse = MockedMiddleware.cardEssentialRequestMiddleware(
                        request,
                    )
                    if (unauthorizedResponse) {
                        return unauthorizedResponse
                    }
                    const max = request.params.max;
                    const paginate = request.params.pagination_page;
                    let  transactions;
                    if(typeof max !== 'undefined') {
                        transactions = schema.transactions.get(max).models;
                    } else {
                        transactions = schema.transactions.all().models;
                    }
                    return MockedResponse.getSuccessfulResponse(
                        'Card Transactions',
                        {
                            transactions: {
                                data: transactions
                            }
                        },
                    )
                },
                standardTimeForRequestToComplete,
            )

            this.get(
              '/cards/transactions/query',
              (schema: any, request: Request) => {
                const unauthorizedResponse = MockedMiddleware.cardEssentialRequestMiddleware(
                  request,
                )
                if (unauthorizedResponse) {
                  return unauthorizedResponse
                }

                const {
                  start_date = 0,
                  end_date = new Date(),
                  offset = null,
                  limit = null,
                } = request.queryParams
                // offset = page #
                // limit = amount to show

                const formatYmdToUnix = (date: any) =>
                  new Date(date).setHours(0, 0, 0, 0)

                const startDate = formatYmdToUnix(start_date)
                const endDate = formatYmdToUnix(end_date)
                const transactions = schema.transactions.all().models

                const transactionsFilteredByDate = transactions.filter(
                  ({datetime}: any) =>
                    formatYmdToUnix(datetime) >= startDate &&
                    formatYmdToUnix(datetime) <= endDate,
                )

                return MockedResponse.getSuccessfulResponse(
                  'Card Transactions',
                  {
                    transactions: {
                      data: transactionsFilteredByDate,
                    },
                  },
                )
              },
              standardTimeForRequestToComplete,
            )
        },
    })
}
