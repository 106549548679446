import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import { drawerWidth } from '../Drawer/DrawerComponentStyles'
import {useTheme} from '@material-ui/styles'

interface ToolbarMarginsInterface {
  marginTop: number
  marginRight: number
  marginBottom: number
  marginLeft: number
}

export interface SpaceFromAppbarMarginsInterface {
  desktopMarginTop: number
  mobileSmMarginTop: number
}

const useToolbarMargins = (): ToolbarMarginsInterface => {
  const theme: Theme = useTheme();
  return {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(2),
  }
}

export const useSpaceFromAppbar = (): SpaceFromAppbarMarginsInterface => {
  const theme: Theme = useTheme();
  const toolbarMargins: ToolbarMarginsInterface = useToolbarMargins();

  const defaultMarginSpace: SpaceFromAppbarMarginsInterface = {
    desktopMarginTop: theme.spacing(9),
    mobileSmMarginTop: theme.spacing(14)
  }

  return {
    desktopMarginTop: defaultMarginSpace.desktopMarginTop + toolbarMargins.marginTop + toolbarMargins.marginBottom,
    mobileSmMarginTop: defaultMarginSpace.mobileSmMarginTop + toolbarMargins.marginTop + toolbarMargins.marginBottom,
  }
}

export const AppBarComponentStyles = makeStyles((theme: Theme) => createStyles({
  appBar: {
    backgroundColor: theme.components.appBar.backgroundColor,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    ...useToolbarMargins(),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: theme.components.drawer.menuToggleColor,
  },
}));