import {SET_PROGRAM} from '../actions'

const program = (state = [], action: any) => {
  switch (action.type) {
    case SET_PROGRAM: {
      return {
        program: action.program,
        ...state,
      }
    }
    default: {
      return {...state}
    }
  }
}

export default program
