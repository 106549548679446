import { makeStyles } from '@material-ui/core/styles'
import {Theme} from '@material-ui/core'
import {useMobileSmQuery} from "../../../hooks/useIsMobile";

export const CardBalanceComponentStyles = makeStyles((theme: Theme) => ({
  balance: {
    ...theme.typography.h1,
    color: theme.components.cardInfo.balanceColor,
    fontSize: '3rem',
    // [useMobileSmQuery()]: {
    //   ...theme.typography.h1
    // }
  },
}))