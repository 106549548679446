import { RootStateOrAny, useSelector } from 'react-redux'
import {RouteFactory} from "../navigation/RouteFactory";
import {MenuRouteInterface} from "../navigation/NavigationRoutesInterface";

export function useAvailableRoutes(): MenuRouteInterface[] {
    const currentAuthState: RootStateOrAny = useSelector<RootStateOrAny>(
        state => state.auth?.currentAuthState,
    )
    const objRouteFactory = new RouteFactory(currentAuthState);
    return objRouteFactory.getUsersAuthRoutes();
}