import React, { ReactNode } from 'react'
import { Typography } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'
import { BottomFooterDescriptionComponentStyles } from './BottomFooterDescriptionComponentStyles'

interface BottomFooterDescriptionProps {
  children: ReactNode,
  id?: string
}

export default function BottomFooterDescriptionComponent({ children, id }: BottomFooterDescriptionProps) {
  const styles: ClassNameMap<"root"> = BottomFooterDescriptionComponentStyles()

  return (
    <Typography 
      className={styles.root}
      id={id}
    >
      {children}
    </Typography>
  )
}