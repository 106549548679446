import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import {drawerWidth} from '../../components/Drawer/DrawerComponentStyles'

export const BaseNotificationLayoutStyles = makeStyles((theme: Theme) => {
  return {
    bottomNotificationSection: {
      padding: theme.spacing(1),
      fontSize:'0.85rem',
      textAlign:'center',
      fontWeight:'bold',
      backgroundColor: theme.components.footer.bottomBackgroundColor,
      color: theme.components.footer.bottomColor
    },
  }
})