import React from 'react'
import ReactDOM from 'react-dom'
import {PersistGate} from 'redux-persist/integration/react'
import {Provider} from 'react-redux'
import env from './environment'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {makeServer} from './mirage/server'
import state from './store/configureStore'
import LoadingWidget from './components/Common/LoadingWidget/LoadingWidget'
import {QueryClient, QueryClientProvider} from 'react-query'

if (!env.isProduction) {
  console.log(`Running in ${env.REACT_APP_STAGE} environment`)
}

if (env.isTest) {
  makeServer()
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      notifyOnChangeProps: ['data', 'error'],
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={state.store}>
      <QueryClientProvider client={queryClient}>
        <PersistGate loading={<LoadingWidget />} persistor={state.persistor}>
          <App />
        </PersistGate>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
