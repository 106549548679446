import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import { drawerWidth } from '../../components/Drawer/DrawerComponentStyles'
import {useMobileSmQuery} from '../../hooks/useIsMobile'
import { useSpaceFromAppbar, SpaceFromAppbarMarginsInterface } from '../../components/Header/AppBarComponentStyles'

export const BaseBodyLayoutStyles = makeStyles((theme: Theme) => {
  const  { desktopMarginTop, mobileSmMarginTop }: SpaceFromAppbarMarginsInterface = useSpaceFromAppbar();

  return {
    content: {
      minHeight: '100vh',
      height: '100%',
      overflow: 'hidden',
    },
    mainContent: {
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
    },
    mainContentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    }
  }
})

