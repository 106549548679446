import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

export const FooterDisclaimerComponentStyles = makeStyles((theme: Theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.components.footer.topColor,
    '&:hover': {
        color: theme.components.footer.topHoverColor,
    },
  },
}))