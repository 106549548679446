import React from 'react'
import {Grid, Typography} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCreditCard} from '@fortawesome/free-solid-svg-icons'
import PageTitleWithIconComponent from '../../../components/Common/PageTitleWithIcon/PageTitleWithIconComponent'
import LastFourCardDigitsComponent from '../../../components/Card/LastFourCardDigits/LastFourCardDigitsComponent'
import CardBalanceComponent from '../../../components/Card/CardBalance/CardBalanceComponent'
import CardImageComponent from '../../../components/Card/CardImage/CardImageComponent'
import CardTransactionHistoryComponent from '../../../components/Card/CardTransactionHistory/CardTransactionHistoryComponent'
import {BaseCardInfoLayoutStyles} from './BaseCardInfoLayoutStyles'
import {ClassNameMap} from '@material-ui/styles'

export default function BaseCardInfoLayout() {
  const styles: ClassNameMap = BaseCardInfoLayoutStyles()

  return (
    <Grid
      container
      direction={'row'}
      justify={'center'}
      className={styles.wrapper}
    >
      <Grid item xs={12} md={10} lg={10}>
        <Grid
          container
          direction={'row'}
          justify={'flex-start'}
          className={styles.breadcrumb}
        >
          <Grid item>
            <PageTitleWithIconComponent
              text={'Card'}
              icon={<FontAwesomeIcon icon={faCreditCard} />}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction={'column'}
          justify={'space-around'}
          className={styles.content}
        >
          <Grid container direction={'column'} spacing={1}>
            <Grid item>
              <Typography className={styles.subtitleHeader}>
                Remaining Balance
              </Typography>
            </Grid>

            <Grid item>
              <LastFourCardDigitsComponent />
            </Grid>

            <Grid item>
              <CardBalanceComponent />
            </Grid>

            <Grid item>
              <CardImageComponent />
            </Grid>

            <Grid item xs={12}>
              <CardTransactionHistoryComponent />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
