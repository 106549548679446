import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import {DEFAULT_WHITE} from '../../../styles/colors'


export const LandingDescriptionComponentStyles = makeStyles((theme: Theme) => ({
    root: {
      ...theme.typography.body1,
      color: DEFAULT_WHITE
    }
}));