import env from '../../environment'

export default class Asset {
  private readonly _url: string

  constructor(programName: string, assetName: string) {
    this._url = `${env.CDN_ASSET_URL}/${programName.toLocaleLowerCase()}/${assetName}` // prettier-ignore-line
  }

  get url(): string {
    return this._url
  }
}
