import {createMuiTheme, Theme, ThemeOptions} from '@material-ui/core/styles'
import {PaletteOptions} from '@material-ui/core/styles/createPalette'
import {Program, ProgramTheme} from '../lib/entities'
import {
    DEFAULT_WHITE,
    DEFAULT_BLACK,
    DEFAULT_BLUE,
    DEFAULT_GREEN,
    DEFAULT_RED,
    DEFAULT_YELLOW,
    DEFAULT_DISABLED_GRAY,
} from './colors'
import {essentialThemeTemplate} from './templates/essentialThemeTemplate'

const defaultOverrides: ThemeOptions = {
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    fontSize: '1rem',
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 400,
                    color: DEFAULT_BLACK,
                    backgroundColor: DEFAULT_WHITE,
                    overscrollBehavior: 'none',
                },
            },
        },
    },
}

const statusColorStyles: PaletteOptions = {
    // used to represent potentially dangerous actions or important messages.
    warning: {
        main: DEFAULT_YELLOW,
        contrastText: DEFAULT_WHITE,
    },
    // used to present information to the user that is neutral and not necessarily important.
    info: {
        main: DEFAULT_BLUE,
        contrastText: DEFAULT_WHITE,
    },
    // used to indicate the successful completion of an action that user triggered.
    success: {
        main: DEFAULT_GREEN,
        contrastText: DEFAULT_WHITE,
    },
    // used to represent interface elements that the user should be made aware of.
    error: {
        main: DEFAULT_RED,
        contrastText: DEFAULT_WHITE,
    },
}

const additionalStyles: PaletteOptions = {
    tonalOffset: 0.2,
    contrastThreshold: 3,
    action: {
        disabledBackground: DEFAULT_DISABLED_GRAY
    }
}

const getThemeTemplate = (programTheme: ProgramTheme) => {
    const template: string = programTheme.template.trim().toUpperCase();

    switch (template) {
        case 'ESSENTIAL':
            return essentialThemeTemplate(programTheme);
        default:
            console.error(`Unable to detect template: ${template}. Reverting to current default template.`);
            return essentialThemeTemplate(programTheme);
    }
}

export function createDefaultTheme() {
    return createMuiTheme({
        ...defaultOverrides,
        palette: {
            ...statusColorStyles,
            ...additionalStyles,
        },
    })
}

export function getTheme(program: Program): Theme {
    if (!validTheme(program)) {
        throw new Error('Unable to retrieve Program.')
    }

    const programTheme: ProgramTheme = program.styles
    const themeTemplate: any = getThemeTemplate(programTheme)

    return createMuiTheme({
        ...defaultOverrides,
        palette: {
            ...statusColorStyles,
            ...additionalStyles,
        },
        ...themeTemplate,
    })
}
function validTheme(program: Program): Boolean {
    if (!program) return false;
    if (!program.styles) console.error('Unable to detect Program Theme. Palette will default to the CSSBaseline.');
    return true;
}