import React, {useState} from 'react'
import DrawerMenuItemButtonComponent from '../../Common/Drawer/DrawerMenuItemButton/DrawerMenuItemButtonComponent'
import ResetPinDialogComponent from '../../ResetPin/ResetPinDialog/ResetPinDialogComponent'
import ResetPinConfirmationDialogComponent
  from '../../ResetPin/ResetPinConfirmationDialog/ResetPinConfirmationDialogComponent'

export default function ResetPinDrawerMenuItemComponent() {
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false)

  const toggleResetPinDialog = () => {
    setOpenDialog(!openDialog)
  }

  const toggleResetPinConfirmationDialog = () => {
    setOpenConfirmationDialog(!openConfirmationDialog)
  }

  return (
    <>
      <DrawerMenuItemButtonComponent name={'Reset PIN'} onClick={toggleResetPinDialog} />
      <ResetPinDialogComponent openDialog={openDialog} toggleResetPinDialog={toggleResetPinDialog} toggleResetPinConfirmationDialog={toggleResetPinConfirmationDialog} />
      <ResetPinConfirmationDialogComponent openConfirmationDialog={openConfirmationDialog} toggleResetPinConfirmationDialog={toggleResetPinConfirmationDialog} />
    </>
  )
}