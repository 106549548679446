export enum StateConstants {
  UNAUTHENTICATED = 'unauthenticated',
  AUTHENTICATED = 'authenticated',
  CARD_USER_ONLY = 'cardVerified',
}

export enum TransitionConstants {
  LOG_IN = 'logIn',
  CHECK_CARD_BALANCE = 'checkCardBalance',
  ACTIVATE_CARD = 'activateCard',
  LOG_OUT = 'logOut',
  EXIT_CARD_ONLY_USER = 'invalidateCard',
}
