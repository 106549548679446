import React, {useState, useEffect, Dispatch} from 'react'
import {useDispatch} from 'react-redux'
import env from './environment'
import {Redirect, Route, Router, Switch} from 'react-router-dom'
import history from './navigation/history'
import {CssBaseline, Theme} from '@material-ui/core'
import {ThemeProvider} from '@material-ui/styles'
import {createDefaultTheme, getTheme} from './styles/theme'
import ProgramIdentificationInteractor from './lib/useCases/ProgramIdentificationInteractor'
import {Program} from './lib/entities'
import {SET_PROGRAM} from './actions'
import {SnackbarProvider} from 'notistack'
import {SnackbarUtilsConfigurator} from './modals/SnackbarNotificationModel'
import {Constants} from './core/Constants'
import {RouteConstants} from './navigation/RouteConstants'
import ProtectedRoute from './navigation/ProtectedRoute'
import LoadingWidget from './components/Common/LoadingWidget/LoadingWidget'
import RootLayout from './layouts/RootLayout'
import BaseHeaderLayout from './layouts/HeaderLayouts/BaseHeaderLayout'
import BaseNotificationLayout from './layouts/NotificationLayouts/BaseNotificationLayout'
import BaseBodyLayout from './layouts/BodyLayouts/BaseBodyLayout'
import BaseFooterLayout from './layouts/FooterLayouts/BaseFooterLayout'
import CardInfoPage from './pages/CardInfoPage'
import CheckBalancePage from './pages/CheckBalancePage'
import ActivateCardPage from './pages/ActivateCardPage'
import ContactUsPage from './pages/ContactUsPage'
import HomeRoutes from './navigation/HomeRoutes'
import HelpPage from './pages/HelpPage'
import DebugBarLayout from './layouts/DebugBar/DebugBarLayout'

export default function App() {
  const [loading, setLoading] = useState<boolean>(true)
  const [theme, setTheme] = useState<Theme>(createDefaultTheme())
  const dispatch: Dispatch<any> = useDispatch()

  useEffect(() => {
    async function getProgramInfo() {
      try {
        let response: any = {}
        const url = localStorage.getItem('program_origin')
        if (env.isDevelopment && url && url !== 'none') {
          response = await ProgramIdentificationInteractor.getProgramByUrl(url)
        } else {
          response = await ProgramIdentificationInteractor.getProgramInfo()
        }

        if (response.status === Constants.HTTP_OK) {
          const program: Program = new Program(response.data.data)
          const programTheme: Theme = getTheme(program)
          setTheme(programTheme)
          dispatch({type: SET_PROGRAM, program: program})
        }

        setLoading(false)
      } catch (e) {
        console.warn(e)
        setLoading(false)
      }
    }
    getProgramInfo()
  }, [dispatch])

  return (
    <>
      {loading && <LoadingWidget />}

      {!loading && (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RootLayout>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{horizontal: 'center', vertical: 'top'}}
            >
              <SnackbarUtilsConfigurator />
              <Router history={history}>
                <BaseHeaderLayout />
                <BaseNotificationLayout />
                <BaseBodyLayout>
                  <Switch>
                    <ProtectedRoute path={RouteConstants.ACTIVATE_CARD_ROUTE}>
                      <ActivateCardPage />
                    </ProtectedRoute>
                    <ProtectedRoute
                      path={RouteConstants.CHECK_CARD_BALANCE_ROUTE}
                    >
                      <CheckBalancePage />
                    </ProtectedRoute>
                    <ProtectedRoute path={RouteConstants.HELP_ROUTE}>
                      <HelpPage />
                    </ProtectedRoute>
                    <ProtectedRoute path={RouteConstants.CONTACT_US_ROUTE}>
                      <ContactUsPage />
                    </ProtectedRoute>
                    <ProtectedRoute path={RouteConstants.CARD_INFO_ROUTE}>
                      <CardInfoPage />
                    </ProtectedRoute>
                    <Route exact path={RouteConstants.HOME_ROUTE}>
                      <HomeRoutes />
                    </Route>
                    <Route path="*">
                      <Redirect to={RouteConstants.HOME_ROUTE} />
                    </Route>
                  </Switch>
                </BaseBodyLayout>
                <BaseFooterLayout />
              </Router>
              {env.isDevelopment && <DebugBarLayout />}
            </SnackbarProvider>
          </RootLayout>
        </ThemeProvider>
      )}
    </>
  )
}
