import React from 'react'
import FooterTitleComponent from '../../Common/Footer/FooterTitle/FooterTitleComponent'
import TopFooterDescriptionComponent from '../../Common/Footer/TopFooterDescription/TopFooterDescriptionComponent'
import { FooterDisclaimerComponentStyles } from './FooterDisclaimerComponentStyles'
import { ClassNameMap } from '@material-ui/styles'
import {useProgramContent} from '../../../hooks/useProgramContent'
import {RootStateOrAny} from 'react-redux'

export default function FooterDisclaimerComponent() {
  const styles: ClassNameMap<"link"> = FooterDisclaimerComponentStyles()
  const programContent: RootStateOrAny = useProgramContent();

  return (
    <>
      <FooterTitleComponent title={'Disclaimer'} />

      { programContent?.footer?.disclaimerLinks.map((value: { name: string, link: string }, index: number) => (

        <TopFooterDescriptionComponent key={`footer-disclaimerLinks-${index}`}>
          <a className={styles.link} href={value.link} target='_blank' rel="noopener noreferrer">{value.name}</a>
        </TopFooterDescriptionComponent>

      )) }
    </>
  )
}