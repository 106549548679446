import {SET_USER, UNSET_USER} from '../actions'

const user = (state: any = [], action: any) => {
  switch (action.type) {
    case SET_USER: {
      const newState = state
      newState.user = action.user
      return {
        ...newState,
      }
    }
    case UNSET_USER: {
      const newState = state
      newState.user = undefined
      return {
        ...newState,
      }
    }
    default: {
      return {...state}
    }
  }
}

export default user
