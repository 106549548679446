import React from 'react'
import AppBarComponent from '../../components/Header/AppBarComponent'
import DrawerComponent from '../../components/Drawer/DrawerComponent'

export default function BaseHeaderLayout() {

  return (
    <div id="base-header-layout">
      <AppBarComponent />
      <DrawerComponent />
    </div>
  )
}