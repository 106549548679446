import {getDomain} from 'cross-domain-utils'

interface IEnvironment {
  [key: string]: {
    API_BASE_URL: string
    CDN_ASSET_URL: string
  }
}

const {REACT_APP_STAGE = 'development'} = process.env

const environment: IEnvironment = {
  test: {
    API_BASE_URL: getDomain(window),
    CDN_ASSET_URL: 'https://development.cdn.paymentrise.com',
  },
  development: {
    API_BASE_URL: 'https://development.api.paymentrise.com',
    CDN_ASSET_URL: 'https://development.cdn.paymentrise.com',
  },
  production: {
    API_BASE_URL: 'https://api.paymentrise.com',
    CDN_ASSET_URL: 'https://cdn.paymentrise.com',
  },
}

export default {
  isTest: REACT_APP_STAGE === 'test',
  isDevelopment: REACT_APP_STAGE === 'development',
  isProduction: REACT_APP_STAGE === 'production',
  REACT_APP_STAGE,
  ...environment[REACT_APP_STAGE],
}
