import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import { darken } from '@material-ui/core/styles/colorManipulator'

export const DrawerMenuItemTitleComponentStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:hover': {
      backgroundColor: darken(theme.components.drawer.backgroundColor, theme.selectors.darken),
    }
  },
  drawerTitle: {
    ...theme.typography.h2,
    color: theme.components.drawer.menuButtonTitleColor,
  },
  icon: {
    ...theme.typography.h2,
    color: theme.components.drawer.menuButtonTitleIconColor
  },
}))