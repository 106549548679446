import {StateConstants} from '../lib/stateMachines/StateConstants'

export const initialAuthState = {
  currentAuthState: StateConstants.UNAUTHENTICATED,
  cardAccessToken: '',
  accessToken: ''
}

export const initialCardState = {
  card: {}
}