import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

export const SecondaryButtonComponentStyles = makeStyles((theme: Theme) => ({
  root: {
    textTransform: 'capitalize',
    width: '100%',
    maxWidth: '18rem',
    marginTop: '1em',
    borderRadius: theme.components.button.borderRadius,
    backgroundColor: theme.components.button.secondaryBackgroundColor,
    color: theme.components.button.secondaryColor,
    "&:hover": {
      backgroundColor: theme.components.button.secondaryColor,
      color: theme.components.button.secondaryBackgroundColor,
    },
    "&:disabled": {
      backgroundColor: theme.components.button.secondaryBackgroundColor
    }
  },
}))