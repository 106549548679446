import React from 'react'
import FooterTitleComponent from '../../Common/Footer/FooterTitle/FooterTitleComponent'
import TopFooterDescriptionComponent from '../../Common/Footer/TopFooterDescription/TopFooterDescriptionComponent'
import {useProgramContent} from '../../../hooks/useProgramContent'
import {RootStateOrAny} from 'react-redux'

export default function FooterAddressComponent() {
  const programContent: RootStateOrAny = useProgramContent();

  return (
    <>
      <FooterTitleComponent title={'Address'} />

      { programContent?.footer?.address.map((value: string, index: number) => (
        <TopFooterDescriptionComponent key={`footer-address-${index}`} id={`footer-address-${index}`}>{value}</TopFooterDescriptionComponent>
      )) }
    </>
  )
}