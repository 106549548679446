import {makeStyles} from '@material-ui/core/styles'
import {Theme} from '@material-ui/core'

export const ResetPinConfirmationDialogComponentStyles = makeStyles((theme:Theme) => ({
  dialogContainer: {
    borderRadius: '10px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.components.modal.closeButtonIconColor
  },
  body: {
    ...theme.typography.body1
  },
}))