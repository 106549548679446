import React, {ChangeEvent, useEffect, useState} from 'react'
import {RootStateOrAny} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {IconButton, Tab} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faBars, faBug } from '@fortawesome/free-solid-svg-icons'
import {AppBarMenuTabsComponentStyles, MenuTabs} from './AppBarMenuTabsComponentStyles'
import {useCurrentAuthState} from '../../../hooks/useCurrentAuthState'
import {MenuRouteInterface} from '../../../navigation/NavigationRoutesInterface'
import {useMenuNavigationRoutes} from '../../../hooks/useMenuNavigationRoutes'
import {useIsMobile} from '../../../hooks/useIsMobile'
import {GetNavigationMenuRouteIndex} from '../../../lib/businessObjects/Navigation/GetNavigationMenuRouteIndex'
import {DrawerContextInterface, useDrawerContext} from '../../../hooks/DrawerContext'
import {useIsAuthenticated} from '../../../hooks/useIsAuthenticated'
import {useProgramContent} from '../../../hooks/useProgramContent'

interface Location {
  pathname: string
}

// TODO history and location are referencing the same object, perhaps removing one to improve readability and minorly, performance
export default function AppBarMenuTabsComponent() {
  const styles: any = AppBarMenuTabsComponentStyles()
  const currentAuthState: RootStateOrAny = useCurrentAuthState();
  const history: any = useHistory();
  const location: Location = useLocation();
  const menuRoutes: MenuRouteInterface[] = useMenuNavigationRoutes();
  const isAuthenticated: boolean = useIsAuthenticated()
  const isMobile: boolean = useIsMobile();
  const { toggleDrawerIsOpen }: DrawerContextInterface = useDrawerContext()

  const programContent: RootStateOrAny = useProgramContent();
  
  const [menuIndex, setMenuIndex] = useState<number>(0);

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setMenuIndex(newValue)
  }

  const navigateToPage = (route: MenuRouteInterface) => {
    history.replace(route.path, route.additionalInfo)
  }

  useEffect(() => {
    try {
      const index = GetNavigationMenuRouteIndex.init(currentAuthState, location.pathname)
      setMenuIndex(index);
    } catch (e) {
      console.warn(e);
    }
  }, [currentAuthState, location])

  useEffect(() => {
    const newPath: string = history.location.pathname
    const presentIndex: number = menuRoutes.findIndex(
      (route: MenuRouteInterface) => route.path === newPath,
    )
    if (presentIndex >= 0) {
      setMenuIndex(presentIndex)
    }
  }, [history.location.pathname, menuRoutes])

  return (
    <MenuTabs
      id="appbar-menu-tabs"
      data-testid={"app-bar-menu-tabs"}
      value={menuIndex <= menuRoutes.length ? menuIndex : 0}
      onChange={handleChange}
      aria-label={'icon tabs'}
      variant="standard"
    >

      { isAuthenticated &&
        <Tab
          id="mobile-menu-toggle-tab"
          className={styles.tab}
          style={!isMobile ? {display: 'none'} : {}}
          disableRipple
          icon={<FontAwesomeIcon icon={faBars} className={styles.icon} />}
          onClick={() => toggleDrawerIsOpen()}
          aria-label="Toggle Drawer"
        /> }

      { menuRoutes.map((route: MenuRouteInterface) =>
        route.path &&
        <Tab
            id={`appbar-menu-tab-${route.description}`}
            key={`nav-route-box-${route.description}`}
            style={programContent?.appBar?.title === "Mazda Reimbursement Card" && (route.description === 'Activate Card' || route.description === 'Check Balance') ? {display: 'none'} : {}}
            className={styles.tab}
            disableRipple
            onClick={() => navigateToPage(route)}
            icon={<FontAwesomeIcon icon={typeof route.icon !== 'undefined' ? route.icon : faBug} className={styles.icon} />}
            label={!isMobile ? route.description : null}
            aria-label={`Navigate to ${route.description}`}
          />
        ) }

    </MenuTabs>
  )
}
