import {makeStyles} from '@material-ui/core/styles'
import {Theme} from '@material-ui/core'
import {useMobileSmQuery} from "../../../../hooks/useIsMobile";

export const TopFooterDescriptionComponentStyles = makeStyles((theme: Theme) => ({
  root: {
    ...theme.typography.body1,
    [useMobileSmQuery()]: {
    ...theme.typography.body2,
    }
  },
}))