import React, {useEffect, useState} from 'react'
import { Typography } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'
import { AxiosResponse } from 'axios'
import history from '../../../navigation/history'
import CardInformationInteractor from '../../../lib/useCases/CardInformationInteractor'
import LoadingWidget from '../../Common/LoadingWidget/LoadingWidget'
import { CardBalanceComponentStyles } from './CardBalanceComponentStyles'
import { ProviderContext, useSnackbar } from 'notistack'

export default function CardBalanceComponent() {
  const styles: ClassNameMap<"balance"> = CardBalanceComponentStyles()
  const { enqueueSnackbar }: ProviderContext = useSnackbar()

  const [loading, setLoading] = useState<boolean>(true)
  const [cardBalance, setCardBalance] = useState<string>('')

  useEffect(() => {
    const getCardBalance = async () => {
      try {
        setLoading(true)
        const response: AxiosResponse = await CardInformationInteractor.getCardInfo()
        setCardBalance(
          response?.data?.data?.balance
        )
      } catch (error){
        enqueueSnackbar('Unable to retrieve card balance', {
          variant: 'error'
        })
        history.replace('/');
      }
      setLoading(false)
    }

    getCardBalance()
  }, [cardBalance, enqueueSnackbar])

  return (
    <>
      {
        loading
          ? <LoadingWidget />
          : <Typography
              align={'center'}
              className={styles.balance}
            >
              {/*TODO render trailing zero. Currently trailing zero is not shown on page*/}
              ${cardBalance}
            </Typography>
      }
    </>
  )
}