import React from 'react'
import { Button } from '@material-ui/core'
import { SecondaryButtonComponentStyles } from './SecondaryButtonComponentStyles'
import { ClassNameMap } from '@material-ui/styles'

interface SecondaryButtonComponentInterface {
  onClick: Function
  text: string
  styles?: ClassNameMap
  disabled?: boolean
  icon?: JSX.Element
}

export default function SecondaryButtonComponent(props: SecondaryButtonComponentInterface) {
  const styles: ClassNameMap<"root"> = SecondaryButtonComponentStyles();

  return (
    <Button
      fullWidth
      className={styles.root}
      disabled={props.disabled ?? false}
      style={props.styles ?? {}}
      variant="contained" // this is giving boxshadow but will override with MUI default theme as fallback
      startIcon={props.icon ?? null}
      onClick={() => props.onClick()}
    >
      {props.text}
    </Button>
  )
}
