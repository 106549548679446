import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

export const CardImageComponentStyles = makeStyles((theme: Theme) => ({
  image: {
    maxHeight: '12rem',
    maxWidth: '100%',
    width: 'auto',
    margin: '0 auto',
    marginTop: '1em',
    marginBottom: '1em',
  },
}))