import {
  SET_AUTH_STATE,
  SET_CARD_ACCESS_TOKEN,
  UNSET_AUTH_STATE,
} from '../actions'
import {initialAuthState} from "./initialState";

const auth = (state: any = initialAuthState, action: any) => {
  switch (action.type) {
    case SET_AUTH_STATE: {
      const newState = state
      newState.currentAuthState = action.currentAuthState
      return {
        ...newState,
      }
    }
    case UNSET_AUTH_STATE: {
      const newState = state
      newState.currentAuthState = undefined
      newState.cardAccessToken = ''
      return {
        ...newState,
      }
    }
    case SET_CARD_ACCESS_TOKEN: {
      const newState = state
      newState.cardAccessToken = action.cardAccessToken
      return {
        ...newState,
      }
    }
    default: {
      return {...state}
    }
  }
}

export default auth
