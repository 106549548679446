import React from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import Asset from '../../../lib/entities/Asset'
import { Constants } from '../../../core/Constants'
import { ClassNameMap } from '@material-ui/styles'
import { CardImageComponentStyles } from './CardImageComponentStyles'

const getCardImage = (program: RootStateOrAny) => (
  program !== undefined
    ? new Asset(program.name.toLocaleLowerCase(), Constants.STATIC_ASSET_CARD)
    : new Asset(
    Constants.STATIC_ASSET_BASE_KEYWORD,
    Constants.STATIC_ASSET_CARD,
    )
)

export default function CardImageComponent() {
  const styles: ClassNameMap<"image"> = CardImageComponentStyles()
  const program: RootStateOrAny = useSelector<RootStateOrAny>(state => state.program?.program)
  const cardAsset: Asset = getCardImage(program)

  return (
    <img
      alt={'card'}
      className={styles.image}
      src={cardAsset.url}
    />
  )
}