import {useHistory} from 'react-router-dom'
import React, {Dispatch, useState} from 'react'
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux'
import {ProviderContext, useSnackbar} from 'notistack'
import {DrawerContextInterface, useDrawerContext} from '../../../hooks/DrawerContext'
import SignOutInteractor from '../../../lib/useCases/SignOutInteractor'
import {StateValue} from 'xstate'
import AuthenticationStateMachine from '../../../lib/stateMachines/AuthenticationStateMachine'
import {StateConstants, TransitionConstants} from '../../../lib/stateMachines/StateConstants'
import {SET_AUTH_STATE, SET_CARD_ACCESS_TOKEN, UNSET_CARD_DATA, UNSET_USER} from '../../../actions'
import {RouteConstants} from '../../../navigation/RouteConstants'
import LoadingWidget from '../../Common/LoadingWidget/LoadingWidget'
import DrawerMenuItemTitleComponent from '../../Common/Drawer/DrawerMenuItemTitle/DrawerMenuItemTitleComponent'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons'

export default function DrawerEndSessionComponent() {
  const history: any = useHistory()
  const dispatch: Dispatch<any> = useDispatch()
  const { enqueueSnackbar }: ProviderContext = useSnackbar()
  const { toggleDrawerIsOpen }: DrawerContextInterface = useDrawerContext()

  const currentAuthState: RootStateOrAny = useSelector<RootStateOrAny>(
    state => state.auth?.currentAuthState,
  )

  const [loading, setLoading] = useState(false)

  const unauthenticateUser = async () => {
    try {
      await SignOutInteractor.signOut()
    } catch (e) {
      console.log(e)
    }
    const resultantState: StateValue = AuthenticationStateMachine.transition(
      currentAuthState,
      TransitionConstants.LOG_OUT,
    ).value
    dispatch({type: SET_AUTH_STATE, currentAuthState: resultantState})
    dispatch({type: UNSET_USER})
  }

  const invalidateCard = async () => {
    const resultantState: StateValue = AuthenticationStateMachine.transition(
      currentAuthState,
      TransitionConstants.EXIT_CARD_ONLY_USER,
    ).value
    dispatch({type: SET_AUTH_STATE, currentAuthState: resultantState})
    dispatch({type: SET_CARD_ACCESS_TOKEN})
    dispatch({type: UNSET_CARD_DATA})
  }

  const endSession = async () => {
    setLoading(true)

    switch (currentAuthState) {
      case StateConstants.AUTHENTICATED: {
        await unauthenticateUser()
        break
      }
      case StateConstants.CARD_USER_ONLY: {
        await invalidateCard()
        break
      }
      default: {
        setLoading(false)
        enqueueSnackbar('Unknown Error!', {
          variant: 'error',
          key: 'logout_error',
          preventDuplicate: true,
        })
        toggleDrawerIsOpen()
        return
      }
    }

    enqueueSnackbar('Successfully logged out', {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      variant: 'success',
    })
    toggleDrawerIsOpen()
    setLoading(false)
    history.replace(RouteConstants.HOME_ROUTE)
  }

  if (loading) return <LoadingWidget />

  return (
    <DrawerMenuItemTitleComponent onClick={() => endSession()} title={'End Session'} icon={<FontAwesomeIcon icon={faSignOutAlt} />} />
  )
}