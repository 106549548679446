import axios from 'axios'
import state from '../../store/configureStore'
import { ApiClientService } from "./ApiClientService";
import { ApiErrorHandler } from "./ApiErrorHandler";
import { CardActivationError } from "../errors/CardActivationError";

state.store.subscribe(listener)

function setAuthToken(accessToken: string) {
  axios.defaults.headers.common.Authorization = 'Bearer ' + accessToken
}

function select(state: any) {
  const userAuthToken = state?.user?.user?.accessToken
  const cardVerifiedAuthToken = state?.auth?.cardAccessToken
  if (userAuthToken) {
    return userAuthToken
  }
  return cardVerifiedAuthToken
}

function listener() {
  const accessToken = select(state.store.getState())
  if (accessToken) {
    setAuthToken(accessToken)
  }
}

export default class ApiRequestService {
  static async get(endpoint: string, params: object = {}) {
    try {
      return await ApiClientService.init().get(endpoint, {
        data: {},
        headers: { 'Content-Type': 'application/json' },
        params
      });
    } catch (error) {
      ApiErrorHandler.init(error);
    }
  }

  /**
   * @throws Error
   * @param endpoint
   * @param body
   */
  static async post(endpoint: string, body?: object) {
    if (!body) {
      body = {}
    }
    try {
      return await ApiClientService.init().post(endpoint, body, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json'
        },
      })
    } catch (error) {
      ApiErrorHandler.init(error);
    }
  }

  static async put(endpoint: string, data?: object) {
    try {
      return await ApiClientService.init().put(endpoint, data)
    } catch (error) {
      ApiErrorHandler.init(error);
    }
  }

  static async delete(endpoint: string, data?: object) {
    try {
      return await ApiClientService.init().delete(endpoint, data)
    } catch (error) {
      ApiErrorHandler.init(error);
    }
  }
}
