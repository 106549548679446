import React from 'react'
import BottomFooterDescriptionComponent
  from '../../Common/Footer/BottomFooterDescription/BottomFooterDescriptionComponent'
import {useProgramContent} from '../../../hooks/useProgramContent'
import {RootStateOrAny} from 'react-redux'

export default function CompanyDisclaimerComponent() {
  const programContent: RootStateOrAny = useProgramContent();

  return (
    <BottomFooterDescriptionComponent id={'footer-companyDisclaimerText'}>
      {programContent?.footer?.companyDisclaimerText}
    </BottomFooterDescriptionComponent>
  )
}