import {Theme, useMediaQuery} from '@material-ui/core'
import {useTheme} from '@material-ui/styles'

export const useMobileXsQuery = (): string => {
  const theme: Theme = useTheme();
  return theme.breakpoints.down('xs');
};

export const useMobileSmQuery = (): string => {
  const theme: Theme = useTheme();
  return theme.breakpoints.down('sm');
};

export const useMobileMdQuery = (): string => {
  const theme: Theme = useTheme();
  return theme.breakpoints.down('md');
};

export const useMobileLgQuery = (): string => {
  const theme: Theme = useTheme();
  return theme.breakpoints.down('lg');
};

export const useMobileXlQuery = (): string => {
  const theme: Theme = useTheme();
  return theme.breakpoints.down('xl');
};

export const useIsMobile = (): boolean => {
  const mobileSmQuery: string = useMobileSmQuery();
  return useMediaQuery(mobileSmQuery);
};