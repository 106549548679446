import React, { ReactNode } from 'react'
import { Typography } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'
import {PageTitleComponentStyles} from './PageTitleComponentStyles'

interface PageTitleProps {
  children: ReactNode
}

export default function PageTitleComponent({children}: PageTitleProps) {
  const styles: ClassNameMap = PageTitleComponentStyles()

  return (
    <Typography  
      className={styles.title}
      align='center'
      gutterBottom
    >     
      {children}
    </Typography>
  )
}