import {combineReducers} from 'redux'
import program from './program'
import user from './user'
import auth from './auth'
import card from './card'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const userPersistConfig = {
  key: 'user',
  storage: storage,
}

const authPersistConfig = {
  key: 'auth',
  storage: storage,
}

const cardPersistConfig = {
  key: 'card',
  storage: storage,
}

export default combineReducers({
  user: persistReducer(userPersistConfig, user),
  auth: persistReducer(authPersistConfig, auth),
  card: persistReducer(cardPersistConfig, card),
  program,
})
