import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import { drawerWidth } from '../../components/Drawer/DrawerComponentStyles'
import { useMobileSmQuery } from '../../hooks/useIsMobile'
import { useSpaceFromAppbar, SpaceFromAppbarMarginsInterface } from '../../components/Header/AppBarComponentStyles'

export const DebugBarLayoutStyles = makeStyles((theme: Theme) => {
  return {
    title: {
      ...theme.typography.h1,
    },
    wrapper: {
      padding: theme.spacing(2),
      backgroundColor: 'white',
      textAlign: 'center',
    }
  }
})