import React from 'react'
import PageDescriptionComponent from '../../Common/PageDescription/PageDescriptionComponent'
import PrimaryButtonComponent from '../../Common/PrimaryButton/PrimaryButtonComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { RouteConstants } from '../../../navigation/RouteConstants'
import { useHistory } from 'react-router-dom'


export default function HelpDescriptionComponent() {
    const history: any = useHistory()

  return (
    <PageDescriptionComponent>
      Frequently Asked Questions
      <PrimaryButtonComponent
        onClick={() => history.push(RouteConstants.HELP_ROUTE)}
        text={'Download FAQ (.PDF)'}
        icon={<FontAwesomeIcon icon={faDownload} />}
        />
    </PageDescriptionComponent>
  )
}