import ApiRequestService from '../services/ApiRequestService'

interface ICardTransactions {
  offset?: number
  limit?: number
}
export default class CardActivationInteractor {
  public static async getCardInfo(): Promise<any> {
    return ApiRequestService.get('/cards/info')
  }

  public static async userHasCard(): Promise<boolean> {
    try {
      const response = await this.getCardInfo()
      return response.status === 200
    } catch (e) {
      return false
    }
  }

  public static async getCardTransactions({
    offset = 1,
    limit = 10,
  }: ICardTransactions): Promise<any> {
    try {
      const response = await ApiRequestService.get('/cards/transactions', {
        offset,
        limit,
      })
      return response?.data?.data?.transactions?.data
    } catch (error) {
      return null
    }
  }
}
