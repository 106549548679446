import ApiRequestService from '../services/ApiRequestService'
import {CardActivationError} from "../errors/CardActivationError";

export default class CardActivationInteractor {
  /**
   * @throws Error
   */
  public static async activateCard(): Promise<any> {
    try {
      return await ApiRequestService.post('/cards/activate')
    } catch (error){
      console.log('testing')
      throw new CardActivationError
    }
  }

  public static async resetPin(newPin: string): Promise<any> {
    return ApiRequestService.put('/cards/pin/reset', {
      pin: newPin,
    })
  }
}
