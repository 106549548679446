import ApiRequestService from '../services/ApiRequestService'
import {CardValidationError} from "../errors/CardValidationError";
import {CardAuthenticationError} from "../errors/CardAuthenticationError";

export default class CardVerifiedInteractor {
  public static async validateCardWithinOurSystem(
    cardNumber: string,
    programIds: string[]
  ): Promise<any> {
    try {
      return await ApiRequestService.post('/cards/verification', {card_number: cardNumber, program_ids: programIds})
    } catch (error){
      throw new CardValidationError();
    }
  }

  public static async authenticateCardWithinOurSystemWithNoSecondaryValidation(): Promise<any> {
    try {
      return await ApiRequestService.post('/cards/authentication')
    } catch (error) {
      throw new CardAuthenticationError();
    }
  }

  public static async authenticateCardWithinOurSystemWithVINSecondaryValidation(vinNumber: string): Promise<any> {
    try {
      return await ApiRequestService.post('/cards/authentication/vin', {
        vin: vinNumber
      })
    } catch (error){
      throw new CardAuthenticationError();
    }
  }

  public static async authenticateCardWithinOurSystemWithSSNSecondaryValidation(lastFourSSNNumber: string): Promise<any> {
    try {
      return await ApiRequestService.post('/cards/authentication/identification', {
        identification: lastFourSSNNumber
      });
    } catch (error){
      throw new CardAuthenticationError();
    }
  }

  public static async authenticateCardWithinOurSystemWithPhoneSecondaryValidation(phoneNumber: string): Promise<any> {
    try {
      return await ApiRequestService.post('/cards/authentication/phone', {
        phone: phoneNumber
      })
    } catch (error){
      throw new CardAuthenticationError();
    }
  }
}
