import {darken, makeStyles, withStyles} from '@material-ui/core/styles'
import {Tabs, Theme} from '@material-ui/core'
import { useMobileXsQuery, useMobileSmQuery, useMobileMdQuery, useMobileLgQuery } from '../../../hooks/useIsMobile'

export const AppBarMenuTabsComponentStyles = makeStyles((theme: Theme) => ({
  tab: {
    ...theme.typography.h4,
    color: theme.components.appBar.menuTabsColor,
    textTransform: 'capitalize',
    [useMobileSmQuery()]: {
      marginLeft: theme.spacing(0),
      width: 'auto',
    },
    '&.MuiTab-textColorInherit': {
      color: darken(theme.palette.action.disabledBackground, theme.selectors.darken),
      opacity: 1,
    },
    '&.MuiTab-textColorInherit.Mui-selected': {
      color: theme.components.appBar.menuTabsColor,
      opacity: 1,
    }
  },
  icon: {
    fontSize: '1.5rem',
    [useMobileSmQuery()]: {
      fontSize: '1rem',
    },
  }
}))

export const MenuTabs = withStyles((theme: Theme) => ({
  indicator: {
    backgroundColor: theme.components.appBar.menuTabsIndicatorColor,
    height: '0.10rem',
    width: '100%'
  },
  flexContainer: {
    justifyContent: 'flex-end',
    flexWrap: 'nowrap',
    [useMobileSmQuery()]: {
      justifyContent: 'space-evenly'
    }
  }
}))(Tabs);