import React, { ReactNode } from 'react'
import {Redirect} from 'react-router-dom'
import {RouteConstants} from "./RouteConstants";
import {useCurrentAuthState} from "../hooks/useCurrentAuthState";
import {useAvailableRoutes} from "../hooks/useAvailableRoutes";
import {RootStateOrAny} from 'react-redux'
import {userStateHomeRoutes} from "./RouteDefinitions";
import {MenuRouteInterface} from "./NavigationRoutesInterface";

interface ProtectedRouteInterface {
  children: ReactNode
  path: string
}

function ProtectedRoute(props: ProtectedRouteInterface) {
  const currentAuthState: RootStateOrAny = useCurrentAuthState();
  const availableRoutesForAuthState = useAvailableRoutes();
  const routeIsAvailableForAuthState: boolean = availableRoutesForAuthState.some((route: MenuRouteInterface) => route.path === props.path);

  if (routeIsAvailableForAuthState) {
    return <>{props.children}</>
  } else {
    console.log(`Unauthenticated redirecting... to ${userStateHomeRoutes[currentAuthState]}`);
    return <Redirect from={RouteConstants.HOME_ROUTE} to={{pathname: userStateHomeRoutes[currentAuthState]}} />
  }
}

export default ProtectedRoute
