import axios from 'axios'
import {NetworkEnums} from '../enums/NetworkEnums'
import env from '../../environment'

export class ApiClientService {
  public static init() {
    const instance = axios.create({
      baseURL: env.API_BASE_URL,
      headers: {
        Accept: '*/*',
      },
      // validateStatus: function (status) {
      //     return status >= 200 && status < 300;
      // }
    })
    instance.defaults.timeout = NetworkEnums.NETWORK_TIMEOUT_IN_MILLIS
    instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
    return instance
  }
}
