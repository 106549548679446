import React, { useState, ChangeEvent, MouseEvent } from 'react'
import { Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripVertical } from '@fortawesome/free-solid-svg-icons'
import PrimaryButtonComponent from '../../Common/PrimaryButton/PrimaryButtonComponent'
import CardActivationInteractor from '../../../lib/useCases/CardActivationInteractor'
import ApiResponseService from '../../../lib/services/ApiResponseService'
import { ProviderContext, useSnackbar } from 'notistack'
import LoadingWidget from '../../Common/LoadingWidget/LoadingWidget'
import {AxiosResponse} from 'axios'
import StyledTextFieldComponent from '../../Common/StyledTextField/StyledTextFieldComponent'
interface ResetPinFormComponentProps {
  toggleResetPinDialog: () => void
  toggleResetPinConfirmationDialog: () => void
}

interface PinFormData {
  newPin: string
  confirmNewPin: string
}

const initialResetPinState = {
  newPin: '',
  confirmNewPin: ''
}

export default function ResetPinFormComponent(props: ResetPinFormComponentProps) {
  const [pinFormData, setPinFormData] = useState<PinFormData>(initialResetPinState)
  const [loading, setLoading] = useState<boolean>(false)
  const { enqueueSnackbar }: ProviderContext = useSnackbar()

  const MAX_PIN_COUNT: number = 4

  const handleFormData = (event: ChangeEvent<HTMLInputElement>): void => {
    setPinFormData({...pinFormData, [event.target.name]: event.target.value})
  }

  const isValidPinLength = (pin: string): boolean => (pin.length === MAX_PIN_COUNT)

  const pinInputsMatch = (pinFormData: PinFormData): boolean => (pinFormData && pinFormData.newPin === pinFormData.confirmNewPin)

  const resetPinFormIsValid = (pinFormData: PinFormData): boolean => isValidPinLength(pinFormData.newPin) && isValidPinLength(pinFormData.confirmNewPin) && pinInputsMatch(pinFormData)

  const resetPin = async (pinFormData: PinFormData) => {
    const { newPin }: PinFormData = pinFormData
    const response: AxiosResponse = await CardActivationInteractor.resetPin(newPin)

    if (!ApiResponseService.responseWasSuccessful(response)) {
      setLoading(false)
      return enqueueSnackbar('Failed to reset PIN', {
        variant: 'error',
      })
    }
  }

  const handleFormSubmit = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setLoading(true)

    try {
      if (!resetPinFormIsValid(pinFormData)) {
        return enqueueSnackbar('Invalid PIN', {
          variant: 'error',
        })
      }

      await resetPin(pinFormData)

      setLoading(false)

      props.toggleResetPinDialog()
      props.toggleResetPinConfirmationDialog()

      // return enqueueSnackbar('Successfully reset PIN', {
      //   anchorOrigin: {
      //     vertical: 'top',
      //     horizontal: 'center',
      //   },
      //   variant: 'success',
      // })

    } catch (error) {
      setLoading(false)
      enqueueSnackbar('Failed to reset pin', {
        variant: 'error',
      })
    }
  }

  return (
    <Grid>
      {loading && <LoadingWidget />}
      <form autoComplete="off" data-testid={'reset-pin-form'} onFocus={() => {}}>
        <StyledTextFieldComponent
          id={'new-pin'}
          testId={'new-pin-input'}
          label={'Enter New 4-Digit PIN'}
          name={'newPin'}
          autoFocus={true}
          required={true}
          inputProps={{
          minLength: MAX_PIN_COUNT,
          maxLength: MAX_PIN_COUNT,
        }}
          onInput={(event: ChangeEvent<HTMLInputElement>) => {
            event.target.value = event.target.value.replace(/[^0-9]/g, '')
          }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormData(event)}
        />
        <StyledTextFieldComponent
          id={"confirm-new-pin"}
          testId={'new-pin-confirmation-input'}
          label={"Confirm New 4-digit PIN"}
          name={'confirmNewPin'}
          required={true}
          autoFocus={false}
          inputProps={{
            minLength: MAX_PIN_COUNT,
            maxLength: MAX_PIN_COUNT,
          }}
          onInput={(event: ChangeEvent<HTMLInputElement>) => {
            event.target.value = event.target.value.replace(/[^0-9]/g, '')
          }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormData(event)}
        />
      </form>

      <PrimaryButtonComponent
        testId={'new-pin-submission-button'}
        onClick={(event: MouseEvent<HTMLButtonElement>) => handleFormSubmit(event)}
        text={'Reset PIN'}
        icon={<FontAwesomeIcon icon={faGripVertical} />}
        disabled={!resetPinFormIsValid(pinFormData) || loading}
      />
    </Grid>

  )
}