import {createDefaultTheme} from '../../styles/theme'
import {Constants} from '../../core/Constants'
import Asset from './Asset'

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    components: any
    selectors: any
    template: string
    colors: any
    fonts: any
    shapes: any
  }
}

export interface ProgramTheme {
  template: string
  colors: {
    primary: {
      main: string
      contrast: string
    },
    secondary: {
      main: string
      contrast: string
    },
    runner: {
      main: string
      contrast: string
    },
    base: {
      main: string
      contrast: string
    },
    paper: {
      main: string
      contrast: string
    },
    legal: {
      main: string
      contrast: string
    },
    selector: {
      darken_coefficient: number
    }
  }
  fonts: {
    family: {
      source: string
      heading: string
      body: string
    },
    size: {
      xl: string
      large: string
      medium: string
      small: string
      xs: string
    },
    weight: {
      normal: number
      bold: number
    },
  },
  shapes: {
    button: {
      border_radius: string
    },
    container: {
      border_radius: string
    }
  }
}

export class Program {
  private readonly _name: string = ''
  private readonly _url: string = ''
  private readonly _programId: string = ''
  private readonly _styles: ProgramTheme = createDefaultTheme()
  private readonly _content: object = {}
  private readonly _rules: object = {}

  constructor(programData: {
    name: string
    url: string
    programId: string
    styles: ProgramTheme
    content: object
    rules: object
  }) {
    try {
      this._name = programData.name
      this._url = programData.url
      this._programId = programData.programId
      this._styles = programData.styles
      this._content = programData.content
      this._rules = programData.rules
    } catch (e) {
      console.warn(e)
    }
  }

  get styles(): ProgramTheme {
    return this._styles
  }
  get programId(): string {
    return this._programId
  }
  get name(): string {
    return this._name
  }
  get logo(): Asset {
    return new Asset(this._name, Constants.STATIC_ASSET_LOGO)
  }
  get content(): object {
    return this._content
  }
  get landing(): Asset {
    return new Asset(this._name, Constants.STATIC_ASSET_LANDING)
  }
  get rules(): object {
    return this._rules;
  }
}
