import React from 'react'
import { Grid } from '@material-ui/core'
import ProgramLogoComponent from '../../../components/Header/ProgramLogo/ProgramLogoComponent'
import AppBarMenuTabsComponent from '../../../components/Header/AppBarMenuTabs/AppBarMenuTabsComponent'
import { useIsMobile } from '../../../hooks/useIsMobile'

export default function BaseAppBarLayout() {
  const isMobile: boolean = useIsMobile()

  return (
    <Grid
      container
      direction={!isMobile ? 'row' : 'column'}
      wrap={'nowrap'}
    >

      <Grid
        container
        item
        direction={'row'}
        // justify={!isMobile ? "flex-start" : "space-between"}
        justify={!isMobile ? 'flex-start' : 'space-between'}
        alignItems={'center'}
        wrap={'nowrap'}
      >
        <ProgramLogoComponent />
      </Grid>


      <Grid
        container
        item
        direction={'row'}
        justify={!isMobile ? 'flex-end' : 'center'}
        alignItems={'center'}
        wrap={'nowrap'}
      >
        <Grid
          item
          xs={!isMobile ? undefined : 12}>
          <AppBarMenuTabsComponent />
        </Grid>
      </Grid>

    </Grid>
  )
}
