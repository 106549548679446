import {RouteConstants} from "./RouteConstants";
import {
    faCheckCircle,
    faCreditCard,
    faEnvelope,
    faHome,
    faQuestionCircle,
    faStar,
    faWallet,
    faBars
} from "@fortawesome/free-solid-svg-icons";
import {StateConstants} from "../lib/stateMachines/StateConstants";
import {DrawerRoutesInterface, MenuRoutesInterface} from "./NavigationRoutesInterface";

export const menuNavigationRoutes: MenuRoutesInterface = {
    [RouteConstants.HOME_KEY]: {
        description: RouteConstants.HOME_DESCRIPTION,
        path: RouteConstants.HOME_ROUTE,
        icon: faHome,
        additionalInfo: {
            header: RouteConstants.HOME_DESCRIPTION,
        },
    },
    [RouteConstants.DASHBOARD_KEY]: {
        description: RouteConstants.DASHBOARD_DESCRIPTION,
        path: RouteConstants.DASHBOARD_ROUTE,
        icon: faHome,
        additionalInfo: {
            header: RouteConstants.DASHBOARD_DESCRIPTION,
        },
    },
    [RouteConstants.CARD_INFO_KEY]: {
        description: RouteConstants.CARD_INFO_DESCRIPTION,
        path: RouteConstants.CARD_INFO_ROUTE,
        icon: faCreditCard,
    },
    [RouteConstants.CHECK_CARD_BALANCE_KEY]: {
        description: RouteConstants.CHECK_CARD_BALANCE_DESCRIPTION,
        path: RouteConstants.CHECK_CARD_BALANCE_ROUTE,
        icon: faWallet,
    },
    [RouteConstants.ACTIVATE_CARD_KEY]: {
        description: RouteConstants.ACTIVATE_CARD_DESCRIPTION,
        path: RouteConstants.ACTIVATE_CARD_ROUTE,
        icon: faCheckCircle,
    },
    [RouteConstants.CONTACT_US_KEY]: {
        description: RouteConstants.CONTACT_US_DESCRIPTION,
        path: RouteConstants.CONTACT_US_ROUTE,
        icon: faEnvelope,
    },
    [RouteConstants.HELP_KEY]: {
        description: RouteConstants.HELP_DESCRIPTION,
        path: RouteConstants.HELP_ROUTE,
        icon: faQuestionCircle,
    },
    [RouteConstants.AWARDS_KEY]: {
        description: RouteConstants.AWARDS_DESCRIPTION,
        path: RouteConstants.AWARDS_ROUTE,
        icon: faStar,
        additionalInfo: {},
    },
    [RouteConstants.RESET_PIN_KEY]: {
        description: RouteConstants.RESET_PIN_DESCRIPTION,
        path: RouteConstants.RESET_PIN_ROUTE,
        icon: faQuestionCircle,
        additionalInfo: {},
    },
    [RouteConstants.DRAWER_TOGGLE_KEY]: {
        key: RouteConstants.DRAWER_TOGGLE_KEY,
        description: RouteConstants.DRAWER_TOGGLE_DESCRIPTION,
        path: null,
        icon: faBars,
    },
}

export const drawerRoutes: DrawerRoutesInterface = {
    [RouteConstants.TRANSACTIONS_KEY]: {
        description: RouteConstants.TRANSACTION_HISTORY_DESCRIPTION,
        path: RouteConstants.CARD_INFO_ROUTE,
    },
    [RouteConstants.CHECK_BALANCE_KEY]: {
        description: RouteConstants.CHECK_BALANCE_DESCRIPTION,
        path: RouteConstants.CARD_INFO_ROUTE,
    },
    [RouteConstants.HELP_KEY]: {
        description: RouteConstants.HELP_DESCRIPTION,
        path: RouteConstants.HELP_ROUTE,
    }
}

export const userStateHomeRoutes: any = {
    [StateConstants.UNAUTHENTICATED]: RouteConstants.HOME_ROUTE,
    [StateConstants.CARD_USER_ONLY]:  RouteConstants.CARD_INFO_ROUTE,
    [StateConstants.AUTHENTICATED]: RouteConstants.CARD_INFO_ROUTE
}