import React, {useState, createContext, useContext, ReactNode, Dispatch, SetStateAction} from 'react'

export interface DrawerContextInterface {
  drawerIsOpen: boolean
  setDrawerIsOpen: Dispatch<SetStateAction<boolean>>
  toggleDrawerIsOpen: () => void
}

interface DrawerContextProviderProps {
  children: ReactNode
}

const DrawerContext = createContext<DrawerContextInterface>(undefined!);

export const DrawerContextProvider = ({ children }: DrawerContextProviderProps) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const toggleDrawerIsOpen = () => {
    setDrawerIsOpen(!drawerIsOpen)
  }

  const drawerContextValue: DrawerContextInterface = {
    drawerIsOpen,
    setDrawerIsOpen,
    toggleDrawerIsOpen
  }

  return (
    <DrawerContext.Provider value={drawerContextValue}>
      {children}
    </DrawerContext.Provider>
  )
};

export const useDrawerContext = () => {
  const { drawerIsOpen, setDrawerIsOpen, toggleDrawerIsOpen } = useContext<DrawerContextInterface>(DrawerContext)
  return { drawerIsOpen, setDrawerIsOpen, toggleDrawerIsOpen };
};