import {Machine} from 'xstate'
import {StateConstants, TransitionConstants} from './StateConstants'

const states = {
  states: {
    [StateConstants.UNAUTHENTICATED]: {
      on: {
        [TransitionConstants.LOG_IN]: StateConstants.AUTHENTICATED,
        [TransitionConstants.CHECK_CARD_BALANCE]: StateConstants.CARD_USER_ONLY,
        [TransitionConstants.ACTIVATE_CARD]: StateConstants.CARD_USER_ONLY,
      },
    },
    [StateConstants.AUTHENTICATED]: {
      on: {
        [TransitionConstants.LOG_OUT]: StateConstants.UNAUTHENTICATED,
      },
    },
    [StateConstants.CARD_USER_ONLY]: {
      on: {
        [TransitionConstants.EXIT_CARD_ONLY_USER]: StateConstants.UNAUTHENTICATED,
        [TransitionConstants.LOG_IN]: StateConstants.AUTHENTICATED,
      },
    },
  },
}

export default Machine({
  id: 'authenticationStateMachine',
  initial: StateConstants.UNAUTHENTICATED,
  ...states,
})
