import {makeStyles} from '@material-ui/styles'
import {Theme} from '@material-ui/core/styles'
import {useMobileSmQuery} from '../../../hooks/useIsMobile'

export const ErrorTransactionsHistoryComponentStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      padding: '4rem',
    },
  }),
)

export const CardTransactionHistoryComponentStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      [useMobileSmQuery()]: {
        marginBottom: '2rem',
      },
    },
    tableContainer: {
      width: '100%',
      marginBottom: '1rem',
      borderRadius: theme.components.paper.borderRadius,
      backgroundColor: theme.components.table.backgroundColor,
    },
    tableTitle: {
      ...theme.typography.h2,
      padding: theme.spacing(2),
      color: theme.components.table.titleColor,
    },
    tableHead: {
      ...theme.typography.h3,
      color: theme.components.table.headColor,
      backgroundColor: theme.components.table.headBackgroundColor,
    },
    tableBodyCell: {
      color: theme.components.table.rowColor,
    },
    table: {
      minWidth: 150,
    },
    tablePaginationGroup: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '30px',
    },
    tablePaginationAction: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      color: theme.components.table.rowColor,
      ...theme.typography.body2,
    },
    tablePaginationSelect: {
      color: theme.components.table.rowColor,
      ...theme.typography.body2,
      '&.MuiNativeSelect-select': {
        background: 'none',
      },
    },
    tablePaginationArrows: {
      color: theme.components.table.rowColor,
      ...theme.typography.body1,
    },
  }),
)
