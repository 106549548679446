import React from 'react'
import {ClassNameMap} from '@material-ui/styles'
import {BaseDrawerLayoutStyles} from './BaseDrawerLayoutStyles'
import {useIsMobile} from '../../hooks/useIsMobile'
import {
  DrawerContextInterface,
  useDrawerContext,
} from '../../hooks/DrawerContext'
import {Grid, IconButton} from '@material-ui/core'
import DrawerProfileComponent from '../../components/Common/Drawer/DrawerProfile/DrawerProfileComponent'
import LastFourCardDigitsComponent from '../../components/Card/LastFourCardDigits/LastFourCardDigitsComponent'
import clsx from 'clsx'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import CardDrawerMenuItemsComponent from '../../components/Drawer/CardDrawerMenuItems/CardDrawerMenuItemsComponent'
import DrawerHelpComponent from '../../components/Drawer/DrawerHelp/DrawerHelpComponent'
import DrawerEndSessionComponent from '../../components/Drawer/DrawerEndSession/DrawerEndSessionComponent'
import DrawerDividerComponent from '../../components/Drawer/DrawerDivider/DrawerDividerComponent'

export default function BaseDrawerLayout() {
  const styles: ClassNameMap = BaseDrawerLayoutStyles()
  const isMobile: boolean = useIsMobile()
  const {toggleDrawerIsOpen}: DrawerContextInterface = useDrawerContext()

  return (
    <Grid
      container
      className={styles.wrapper}
      direction={'column'}
      justify={'space-between'}
    >
      <Grid
        container
        direction={'row'}
        justify={'space-between'}
        alignItems={'center'}
      >
        <Grid item xs={10} md={12} lg={12}>
          <DrawerProfileComponent username={'Anonymous'} />
          <LastFourCardDigitsComponent />
        </Grid>

        <Grid item xs={2} md={2} lg={2}>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open mobile drawer"
              onClick={toggleDrawerIsOpen}
              edge="start"
              className={clsx(styles.menuButton)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </IconButton>
          )}
        </Grid>

        <Grid
          container
          direction={'column'}
          data-testid={'middle-drawer-section'}
        >
          <Grid item>
            <DrawerDividerComponent />
            <CardDrawerMenuItemsComponent />
          </Grid>
        </Grid>
      </Grid>

      <Grid item data-testid={'bottom-drawer-section'}>
        <DrawerDividerComponent />
        {/* <DrawerHelpComponent /> */}
        <DrawerEndSessionComponent />
      </Grid>
    </Grid>
  )
}
