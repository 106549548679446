import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import {drawerWidth} from '../../components/Drawer/DrawerComponentStyles'
import {useMobileSmQuery} from '../../hooks/useIsMobile'
import {useSpaceFromAppbar, SpaceFromAppbarMarginsInterface} from '../../components/Header/AppBarComponentStyles'

export const BaseFooterLayoutStyles = makeStyles((theme: Theme) => {
  return {
    footer: {
      clear: 'both',
      position: 'relative',
      minHeight: '64px',
    },
    footerShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    },
    topFooterSection: {
      padding: theme.spacing(2),
      backgroundColor: theme.components.footer.topBackgroundColor,
      color: theme.components.footer.topColor
    },
    bottomFooterSection: {
      padding: theme.spacing(2),
      backgroundColor: theme.components.footer.bottomBackgroundColor,
      color: theme.components.footer.bottomColor
    },
  }
})