import React from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import { LastFourCardDigitsComponentStyles } from './LastFourCardDigitsComponentStyles'
import {ClassNameMap} from '@material-ui/styles'

interface LastFourCardDigitsProps {
  styles?: object
}

export default function LastFourCardDigitsComponent(props: LastFourCardDigitsProps) {
  const card: RootStateOrAny = useSelector<RootStateOrAny>(state => state?.card.cardData.cardData)
  const lastFourDigits: string = card.lastFourDigitsOfCardNumber || '0000'
  const styles: ClassNameMap = LastFourCardDigitsComponentStyles()

  return (
    <Typography className={styles.body}>Ending in **{lastFourDigits}</Typography>
  )
}