import React from 'react';
import { Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWallet } from '@fortawesome/free-solid-svg-icons'
import { RouteConstants } from '../../../navigation/RouteConstants'
import VerifyCardForm from '../../../components/Forms/VerifyCardForm/VerifyCardFormComponent'
import { BaseCheckBalanceLayoutStyles } from './BaseCheckBalanceLayoutStyles'
import { ClassNameMap } from '@material-ui/styles'
import {useHistory} from "react-router-dom";
import SnackbarUtils from "../../../modals/SnackbarNotificationModel";
import {CardInactiveError} from "../../../lib/errors/CardInactiveError";

export default function BaseCheckBalanceLayout() {
  const styles: ClassNameMap = BaseCheckBalanceLayoutStyles()
  const history: any = useHistory()

  return (
    <Grid
      container
      direction={'row'}
      justify={'center'}
      alignItems={'flex-start'}
    >
      <Grid
          item
          sm={12} md={6} lg={6}
          className={styles.wrapper}
        >

          <VerifyCardForm
            pageTitle={'Check Balance'}
            route={RouteConstants.CARD_INFO_ROUTE}
            buttonText={'Check Balance'}
            icon={<FontAwesomeIcon icon={faWallet} />}
            errorMessage={'Error! Please verify the information you have entered.'}
            cardActivationStatusAction={async (cardStatus: number) => {
              if (cardStatus !== 1) {
                history.replace(RouteConstants.ACTIVATE_CARD_ROUTE);
                SnackbarUtils.success('Please Activate Card');
                throw new CardInactiveError();
              }
            }}
          />
      </Grid>
    </Grid>
  )
}