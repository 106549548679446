import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import { useMobileXsQuery, useMobileSmQuery, useMobileMdQuery, useMobileLgQuery } from '../../../hooks/useIsMobile'

export const ProgramLogoComponentStyles = makeStyles((theme: Theme) => ({
  logo: {
    // width: '100%',
    maxWidth: '250px',
    maxHeight: '55px',
    [useMobileMdQuery()]: {
      maxHeight: '50px',
    },
  }
}))