import React from 'react'
import BottomFooterDescriptionComponent
  from '../../Common/Footer/BottomFooterDescription/BottomFooterDescriptionComponent'
import {useProgramContent} from '../../../hooks/useProgramContent'
import {RootStateOrAny} from 'react-redux'

export default function FooterCopyrightComponent() {
  const programContent: RootStateOrAny = useProgramContent();

  return (
    <BottomFooterDescriptionComponent id={'footer-copyright'}>
      {programContent?.footer?.copyright} &copy; {new Date().getFullYear()}
    </BottomFooterDescriptionComponent>
  )
}