import {StateConstants} from "../lib/stateMachines/StateConstants";
import {drawerRoutes, menuNavigationRoutes} from "./RouteDefinitions";
import {CARD_DRAWER_SECTION_KEY, RouteConstants} from "./RouteConstants";
import {DrawerRouteMappingInterface, MenuRouteInterface} from "./NavigationRoutesInterface";
import {faCreditCard} from "@fortawesome/free-solid-svg-icons";

export class RouteFactory {
    constructor(private authState: StateConstants) {}
    public getUsersAuthRoutes(): MenuRouteInterface[] {
        const authStateRoutes: {[authState: string]: MenuRouteInterface[]} = {
            [StateConstants.UNAUTHENTICATED]: [
                menuNavigationRoutes[RouteConstants.CONTACT_US_KEY],
                menuNavigationRoutes[RouteConstants.ACTIVATE_CARD_KEY],
                menuNavigationRoutes[RouteConstants.CHECK_CARD_BALANCE_KEY],
            ],
            [StateConstants.CARD_USER_ONLY]: [
                menuNavigationRoutes[RouteConstants.DRAWER_TOGGLE_KEY],
                menuNavigationRoutes[RouteConstants.CARD_INFO_KEY],
                menuNavigationRoutes[RouteConstants.HELP_KEY],
            ],
            [StateConstants.AUTHENTICATED]: [
                menuNavigationRoutes[RouteConstants.HOME_KEY],
                menuNavigationRoutes[RouteConstants.ACTIVATE_CARD_KEY],
                menuNavigationRoutes[RouteConstants.CARD_INFO_KEY],
                menuNavigationRoutes[RouteConstants.CONTACT_US_KEY],
            ],
        }
        return authStateRoutes[this.authState];
    }
    public getUsersMenuBarRoutes() {
        const userNavigationMenuAuthRoutes: {[authState: string]: MenuRouteInterface[]} = {
            [StateConstants.UNAUTHENTICATED]: [
                menuNavigationRoutes[RouteConstants.HOME_KEY],
                menuNavigationRoutes[RouteConstants.ACTIVATE_CARD_KEY],
                menuNavigationRoutes[RouteConstants.CHECK_CARD_BALANCE_KEY],
                menuNavigationRoutes[RouteConstants.CONTACT_US_KEY],
            ],
            [StateConstants.CARD_USER_ONLY]: [
                menuNavigationRoutes[RouteConstants.DRAWER_TOGGLE_KEY],
                menuNavigationRoutes[RouteConstants.CARD_INFO_KEY],
            ],
            [StateConstants.AUTHENTICATED]: [
                menuNavigationRoutes[RouteConstants.DASHBOARD_KEY],
                menuNavigationRoutes[RouteConstants.CARD_INFO_KEY],
                menuNavigationRoutes[RouteConstants.AWARDS_KEY],
            ],
        }
        return userNavigationMenuAuthRoutes[this.authState];
    }
    public getUsersDrawerRoutes() {
        const userDrawerMenuAuthRoutes: DrawerRouteMappingInterface = {
            [StateConstants.UNAUTHENTICATED]: {},
            [StateConstants.CARD_USER_ONLY]:
                {
                    [CARD_DRAWER_SECTION_KEY]: {
                        name: "Card",
                        icon: faCreditCard,
                        routes: [
                            drawerRoutes[RouteConstants.TRANSACTIONS_KEY],
                            drawerRoutes[RouteConstants.CHECK_BALANCE_KEY]
                        ]
                    }
                }
            ,
            [StateConstants.AUTHENTICATED]: {},
        }
        return userDrawerMenuAuthRoutes[this.authState];
    }
}