import {SET_CARD_DATA} from "../../../actions";
import store from '../../../store/configureStore'

export class SetCardData {
    public static init(cardData: any){
        store.store.dispatch({
            type: SET_CARD_DATA,
            cardData: cardData
        })
    }
}