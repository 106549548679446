import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

// TODO replace with rem
export const DrawerProfileComponentStyles = makeStyles((theme: Theme) => ({
  avatar: {
    fontSize: '5rem',
  },
  username: {
    ...theme.typography.h2,
  },
  roleType: {
    ...theme.typography.body1,
  },
}))