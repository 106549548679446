import React, {useEffect} from 'react'
import {ClassNameMap} from '@material-ui/styles'
import {DrawerComponentStyles} from './DrawerComponentStyles'
import {useIsAuthenticated} from '../../hooks/useIsAuthenticated'
import {useIsMobile} from '../../hooks/useIsMobile'
import {DrawerContextInterface, useDrawerContext} from '../../hooks/DrawerContext'
import { SwipeableDrawer} from '@material-ui/core'
import {isDeviceType} from '../../hooks/useDeviceType'
import BaseDrawerLayout from '../../layouts/DrawerLayouts/BaseDrawerLayout'

export default function DrawerComponent() {
  const styles: ClassNameMap = DrawerComponentStyles();
  const isAuthenticated: boolean = useIsAuthenticated();
  const isMobile: boolean = useIsMobile();
  const { drawerIsOpen, setDrawerIsOpen, toggleDrawerIsOpen }: DrawerContextInterface = useDrawerContext();

  useEffect(() => {
    if (isAuthenticated && !isMobile) {
      setDrawerIsOpen(true)
    } else {
      setDrawerIsOpen(false)
    }
  }, [isAuthenticated, isMobile, setDrawerIsOpen])

  return (
      <SwipeableDrawer
        variant="persistent"
        className={styles.drawer}
        anchor="left"
        open={drawerIsOpen}
        onClose={toggleDrawerIsOpen}
        onOpen={toggleDrawerIsOpen}
        classes={{
          paper: styles.drawerPaper,
        }}
        disableBackdropTransition={!isDeviceType.iOS}
        disableDiscovery={isDeviceType.iOS}
      >

        {isAuthenticated && (<BaseDrawerLayout />)}

      </SwipeableDrawer>
  );
}
