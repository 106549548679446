import React, { MouseEvent } from 'react'
import { Button } from '@material-ui/core'
import { PrimaryButtonComponentStyles } from './PrimaryButtonComponentStyles'
import { ClassNameMap } from '@material-ui/styles'

interface PrimaryButtonComponentInterface {
  onClick: (value: any) => void
  text: string
  disabled?: boolean
  icon?: JSX.Element
  testId?: string
}

export default function PrimaryButtonComponent(props: PrimaryButtonComponentInterface) {
  const styles: ClassNameMap = PrimaryButtonComponentStyles()

  return (
    <Button
      fullWidth
      variant="contained" // this is giving boxshadow but will override with MUI default theme as fallback
      className={styles.root}
      disabled={props.disabled ?? false}
      data-testid={props.testId ?? ''}
      startIcon={props.icon ?? null}
      onClick={(event: MouseEvent<HTMLElement>) => props.onClick(event)}
    >
      {props.text}
    </Button>
  )
}
