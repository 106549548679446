import React from 'react'
import FooterTitleComponent from '../../Common/Footer/FooterTitle/FooterTitleComponent'
import TopFooterDescriptionComponent from '../../Common/Footer/TopFooterDescription/TopFooterDescriptionComponent'
import {useProgramContent} from '../../../hooks/useProgramContent'
import {RootStateOrAny} from 'react-redux'

export default function FooterContactComponent() {
  const programContent: RootStateOrAny = useProgramContent();

  return (
    <>
      <FooterTitleComponent title={'Contact'} />

      { programContent?.footer?.contacts.map((value: string, index: number) => (
        <TopFooterDescriptionComponent key={`footer-contacts-${index}`} id={`footer-contacts-${index}`}>{value}</TopFooterDescriptionComponent>
      )) }

    </>
  )
}