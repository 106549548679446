import React from 'react';
import { Grid } from '@material-ui/core'
// import ContactUsFormComponent from '../../../components/Forms/ContactUsForm/ContactUsFormComponent'
import { BaseContactUsLayoutStyles } from './BaseContactUsLayoutStyles'
import { ClassNameMap } from '@material-ui/styles'
import ContactUsTitleComponent from '../../../components/ContactUs/ContactUsTitle/ContactUsTitleComponent'
import ContactUsDescriptionComponent
  from '../../../components/ContactUs/ContactUsDescription/ContactUsDescriptionComponent'

export default function BaseContactUsLayout() {
  const styles: ClassNameMap = BaseContactUsLayoutStyles()

  return (
    <Grid
      container
      direction={'column'}
      alignItems={'center'}
      id="contact-us"
    >
      <Grid 
        item 
        xs={12} sm={12} md={6} lg={6}
        className={styles.wrapper}
      >
        <Grid item>
          <ContactUsTitleComponent />
        </Grid>

        <Grid
          container
          direction={'row'}
          justify={'center'}
        >

          <ContactUsDescriptionComponent />

          {/* <Grid item xs={12} md={6} lg={6} className={styles.center}>
            <ContactUsFormComponent />
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  )
}