import {Button, TextField} from '@material-ui/core'
import React from 'react'
import {StyledTextFieldComponentStyles} from './StyledTextFieldComponentStyles'
import {ClassNameMap} from '@material-ui/styles'

interface StyledTextFieldProps {
  id: string
  label: string
  name?: string
  value?: string
  autoFocus?: boolean
  autoComplete?: "on" | "off"
  disabled?: boolean
  required?: boolean
  classNameOverride?: any
  inputProps?: any
  onInput?: any
  onChange?: any
  type?: any
  error?: any
  testId?: string
}

export default function StyledTextFieldComponent(props: StyledTextFieldProps) {
  const styles: ClassNameMap = StyledTextFieldComponentStyles();

  return (
    <TextField
      fullWidth
      type="text"
      id={props.id}
      label={props.label}
      name={props.name ?? ''}
      value={props.value ?? undefined}
      autoFocus={props.autoFocus ?? false}
      autoComplete={props.autoComplete ?? "on"}
      disabled={props.disabled ?? false}
      required={props.required ?? false}
      className={props.classNameOverride ?? styles.wrapper}
      // inputRef={input => input && input.focus()}
      data-testid={props.testId ?? ''}
      InputLabelProps={{
        className: styles.inputLabelProps,
      }}
      InputProps={{
        className: styles.inputProps
      }}
      inputProps={{
        ...props.inputProps
      }}
      onInput={props.onInput ?? null}
      onChange={props.onChange ?? null}
    />
  )
}
