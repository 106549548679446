import React from 'react'
import {ClassNameMap} from '@material-ui/styles'
import {ProgramLogoComponentStyles} from './ProgramLogoComponentStyles'
import {useProgramLogo} from '../../../hooks/useProgramLogo'
import history from '../../../navigation/history'

export default function ProgramLogoComponent() {
  const styles: ClassNameMap = ProgramLogoComponentStyles()

  return (
    <img
      id="program-logo"
      alt="program-logo"
      className={styles.logo}
      src={useProgramLogo()}
      onClick={() => history.replace('/')}
    />
  )
}