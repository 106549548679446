import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import {useMobileSmQuery} from "../../../../hooks/useIsMobile";

export const BottomFooterDescriptionComponentStyles = makeStyles((theme: Theme) => ({
  root: {
    ...theme.typography.body2,
    color: theme.components.footer.bottomColor,
    [useMobileSmQuery()]: {
      ...theme.typography.body2,
      }    
  },
}))