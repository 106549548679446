export const DEFAULT_WHITE = '#FFFFFF'
export const DEFAULT_BLACK = '#000000'
export const DEFAULT_GREEN = '#8CC63F'
export const DEFAULT_YELLOW = '#ffd200'
export const DEFAULT_BLUE = '#0075ff'
export const DEFAULT_RED = '#ff0000'
export const DEFAULT_DISABLED_GRAY = '#EDEDED'

const devColors = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  SUSHI_GREEN: '#8CC63F',
  EDEN_BLUE: '#0F3E50',
  GALLERY_GRAY: '#EDEDED',
  MINE_SHAFT_GRAY: '#333333',

  RED: '#FF0000',
  YELLOW: '#FFFF00',
  ORANGE: '#FF7F00',
  PURPLE: '#8B00FF',
  GREEN: '#00FF00',
  BROWN: '#964B00',
  GOLD: '#FFD700',
};
