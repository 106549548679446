import React from 'react'
import { Backdrop, CircularProgress } from '@material-ui/core'
import { LoadingWidgetStyles } from './LoadingWidgetStyles'
import { ClassNameMap } from '@material-ui/styles'

export default function LoadingWidget() {
  const styles: ClassNameMap = LoadingWidgetStyles()

  return (
    <Backdrop className={styles.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
