import {SET_CARD_ACCESS_TOKEN} from "../../../actions";
import store from '../../../store/configureStore'

export class SetCardAccessToken {
    public static init(token: string){
        store.store.dispatch({
            type: SET_CARD_ACCESS_TOKEN,
            cardAccessToken: token,
        })
    }
}