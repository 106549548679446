import React from 'react';
import {RootStateOrAny} from 'react-redux';
import {Redirect} from 'react-router-dom';
import LandingPage from '../pages/LandingPage';
import {useCurrentAuthState} from '../hooks/useCurrentAuthState';
import {StateConstants} from '../lib/stateMachines/StateConstants';
import {userStateHomeRoutes} from "./RouteDefinitions";

export default function HomeRoutes() {
  const currentAuthState: RootStateOrAny = useCurrentAuthState();

  if (currentAuthState === StateConstants.UNAUTHENTICATED) return <LandingPage />;
  return <Redirect to={userStateHomeRoutes[currentAuthState]} />;
}