import React, { ChangeEvent } from 'react'
import StyledTextFieldComponent from '../../Common/StyledTextField/StyledTextFieldComponent'
import {ValidateInput} from "../../../lib/businessObjects/Validation/ValidateInput";

interface VINValidationComponentInterface {
    setSecondaryInformationValue: (value: any) => void;
    incorrectSecondaryInput: boolean;
    setIncorrectSecondaryInput: (value: any) => void
}

export function PhoneValidationComponent(props: VINValidationComponentInterface) {

    return (
        <StyledTextFieldComponent
            id="secondary-information-number"
            label="Enter the last 4 digits of your phone number"
            type="number"
            required={true}
            error={props.incorrectSecondaryInput}
            onInput={(element: ChangeEvent<HTMLInputElement>) => {
                if(!ValidateInput.stringIsNumberOfDigits(element.target.value.toString(), 4)) {
                    props.setIncorrectSecondaryInput(true);
                } else {
                    props.setIncorrectSecondaryInput(false);
                }
            }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => props.setSecondaryInformationValue(event.target.value)}
        />
    )
}