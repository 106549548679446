import {RootStateOrAny} from "react-redux";
import {StateValue} from "xstate";
import AuthenticationStateMachine from "../../stateMachines/AuthenticationStateMachine";
import {TransitionConstants} from "../../stateMachines/StateConstants";
import {SET_AUTH_STATE} from "../../../actions";
import store from '../../../store/configureStore'

export class SetAuthState {
    public static init(currentAuthState: RootStateOrAny, transitionName: TransitionConstants){
        const resultantState: StateValue = AuthenticationStateMachine.transition(currentAuthState, transitionName).value
        store.store.dispatch({type: SET_AUTH_STATE, currentAuthState: resultantState})
    }
}