import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import {DEFAULT_WHITE} from '../../../styles/colors'

export const LoadingWidgetStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1000,
    // hard fallback due to loading being initialized to true before App can fetch theme object
    color: theme.components?.loading?.color || DEFAULT_WHITE
  },
}))