import {
    SET_CARD_DATA,
    UNSET_CARD_DATA
} from '../actions'
import {initialCardState} from "./initialState";

const card = (state: any = initialCardState, action: any) => {
    switch (action.type) {
        case SET_CARD_DATA: {
            const newState = state
            newState.cardData = action.cardData
            return {
                ...newState,
            }
        }
        case UNSET_CARD_DATA: {
            const newState = state
            newState.cardData = undefined
            return {
                ...newState,
            }
        }
        default: {
            return {...state}
        }
    }
}

export default card
