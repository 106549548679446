import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {RootStateOrAny, useSelector} from 'react-redux'
import {useProgramContent} from '../../../hooks/useProgramContent'

export default function HelmetComponent() {
  const program: RootStateOrAny = useSelector<RootStateOrAny>(state => state.program?.program)
  const programContent: RootStateOrAny = useProgramContent();

  return (
    <HelmetProvider>
      <Helmet>
        <title>{programContent?.index?.title}</title>
        <link rel="icon" href={programContent?.index?.favicon} />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href={program.styles?.fonts?.family?.source || "https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&family=Roboto:wght@400;700&display=swap"} rel="stylesheet" />
        <meta name="description" content={program.name} />
      </Helmet>
    </HelmetProvider>
  )
}