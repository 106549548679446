import React from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { BaseNotificationLayoutStyles } from './BaseNotificationLayoutStyles'
import {useProgramContent} from '../../hooks/useProgramContent'
import { ClassNameMap } from '@material-ui/styles'

export default function BaseFooterLayout() {
  const styles: ClassNameMap = BaseNotificationLayoutStyles()
  const program: RootStateOrAny = useSelector<RootStateOrAny>(state => state.program?.program)
  
  const programContent: RootStateOrAny = useProgramContent();

  if(typeof program === 'undefined'){
    return (<div/>);
  }

  if(programContent?.appBar?.title === "Mazda Reimbursement Card"){
    return (<div/>);
  } else {
    return (<div/>);
  }
}
