import React from 'react'
import { Typography } from '@material-ui/core'
import { PageTitleWithIconComponentStyles } from './PageTitleWithIconComponentStyles'
import { ClassNameMap } from '@material-ui/styles'

interface PageTitleWithIconInterface {
  text: string
  icon?: JSX.Element
}

export default function PageTitleWithIconComponent({ text, icon }: PageTitleWithIconInterface) {
  const styles: ClassNameMap = PageTitleWithIconComponentStyles()

  return (
    <Typography
      className={styles.title}
    >
      {icon}&nbsp;
      {text}
    </Typography>
  )
}