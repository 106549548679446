import faker from "faker";
import {getDomain} from "cross-domain-utils";
import MockedJWTInteractor, {TOKEN_TYPES} from "./MockedJWTInteractor";

export const transactionModel = {
    datetime() {
        const m = faker.date.past();
        return m.getUTCFullYear() + "/" +
            ("0" + (m.getUTCMonth() + 1)).slice(-2) + "/" +
            ("0" + m.getUTCDate()).slice(-2) + " " +
            ("0" + m.getUTCHours()).slice(-2) + ":" +
            ("0" + m.getUTCMinutes()).slice(-2) + ":" +
            ("0" + m.getUTCSeconds()).slice(-2);
    },
    location() { return faker.company.companyName() },
    amount() { return faker.finance.amount(10, 1000, 2).toString() },
    transactionType() { return faker.random.arrayElement(['Credit', 'Debit'] ) }
};

export const cardModel = {
    balance: faker.finance.amount(10, 1000, 2).toString(),
};

const paymentRiseProgram = {
    styles: {
        template: 'essential',
        colors: {
            primary: {
                main: '#8CC63F', 
                contrast: '#FFFFFF',
            },
            secondary: {
                main: '#0F3E50',
                contrast: '#FFFFFF'
            },
            runner: {
                main: '#FFFFFF',
                contrast: '#333333'
            },
            base: {
                main: '#FAFAFA',
                contrast: '#333333',
            },
            paper: {
                main: '#FFFFFF',
                contrast: '#33333'
            },
            legal: {
                main: '#000000',
                contrast: '#FFFFFF'
            },
            selector: {
                darken_coefficient: 0.20
            }
        },
        fonts: {
            family: {
                source: "https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&family=Roboto:wght@400;700&display=swap",
                heading: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
                body: '"Roboto", "Helvetica", "Arial", sans-serif',
            },
            size: {
                xl: '2rem',
                large: '1.25rem',
                medium: '1rem',
                small: '0.875rem',
                xs: '0.75rem',
            },
            weight: {
                normal: 400,
                bold: 700,
            },
        },
        shapes: {
            button: {
                border_radius: '20px',
            },
            container: {
                border_radius: '10px',
            }
        }
    },
    content: {
        index: {
            title: 'PaymentRise | Essential',
            favicon: 'https://development.cdn.paymentrise.com/paymentrise/favicon.ico'
        },
        appBar: {
            logo: 'https://development.cdn.paymentrise.com/paymentrise/logo.svg',
            title: 'PaymentRise Reward Mastercard'
        },
        pages: {
            landing: {
                image: 'https://development.cdn.paymentrise.com/paymentrise/landing.jpg',
                title: 'Welcome to PaymentRise',
                description: ['PaymentRise is the best awards and incentives platform on the market. It can streamline archaic paper processes with digital and real-time data. Not only will it boost productivity, it will keep your customers happy with quicker payments.']
            },
            contactUs: {
                rows: ['JNR’s customer service representatives are available to answer your questions Monday through Friday 7:00 a.m. to 5:00 p.m. Pacific Time, except for major holidays. If you don’t find the information you need online, you can contact a representative via email or phone.', 'Do not provide sensitive information via email (i.e. full card number, Social Security Number, etc.)'],
                columns: [
                    {
                        name: 'Email Address:',
                        value: 'cs.jnrcard@jnrcorp.com',
                    },
                    {
                        name: 'Phone Number:',
                        value: '1.888.867.8032',
                    },
                    {
                        name: 'Hours:',
                        value: 'Mon-Fri 7:00am to 5:00pm (PST)',
                    }
                ]
            }
        },
        footer: {
            address: ['19900 MacArthur Blvd.', 'Suite 700', 'Irvine, CA 92612', 'United States of America'],
            contacts: ['Email: cs.jnrcard@jnrcorp.com', 'Phone: 1.888.867.8032', 'Fax: 1.949.955.3825'],
            disclaimerLinks: [
                {
                    name: 'Cardholder Agreement',
                    link: 'https://jnr-disclaimer-files.s3-us-west-2.amazonaws.com/JNRGenericSunriseMC_CHA.pdf',
                },
                {
                    name: 'Sunrise Privacy Policy',
                    link: 'https://jnr-disclaimer-files.s3-us-west-2.amazonaws.com/SunrisePrivacyPolicy.pdf',
                },
                {
                    name: 'JNR Privacy Policy',
                    link: 'https://jnr-disclaimer-files.s3-us-west-2.amazonaws.com/JNRPrivacyPolicy.pdf',
                },
                {
                    name: 'Terms of Use',
                    link: 'https://jnr-disclaimer-files.s3-us-west-2.amazonaws.com/JNRTermsOfUse.pdf',
                }
            ],
            disclaimerText: 'This Card is issued by Sunrise Banks N.A., Member FDIC, pursuant to a license from Mastercard International Incorporated. Mastercard is a registered trademark, and the circles design is a trademark of Mastercard International Incorporated. Card program marketed and administered by JNR Inc. JNR Inc. is an Independent Sales Organization (ISO) pursuant to an agreement with Sunrise Banks, N.A. This Card may be used everywhere Debit Mastercard is accepted. Use of this Card constitutes acceptance of the terms and conditions stated in the Cardholder Agreement.',
            companyDisclaimerText: 'This site is owned and operated by JNR, Inc.',
            copyright: 'JNR Incorporated'
        }
    }
};

export const programModel = {
    name: 'PaymentRise',
    url: getDomain(window),
    programId: '1234',
    styles: {
        ...paymentRiseProgram.styles
    },
    content: {
        ...paymentRiseProgram.content
    },
    rules: {
        associatedIds: [
            {
                id: "L9Jw"
            },
            {
                id: "ERxv"
            },
            {
                id: "qxZ2"
            }
        ]
    }
};

export const userModel = {
    username: faker.internet.userName(),
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    accessToken: MockedJWTInteractor.createMockJWTToken(
        'Test User Token Payload',
        TOKEN_TYPES.USER_AUTHENTICATED,
    ),
};