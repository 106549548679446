import {SET_AUTH_STATE} from "../../../actions";
import store from '../../../store/configureStore'
import {StateValue} from "xstate";
import AuthenticationStateMachine from "../../stateMachines/AuthenticationStateMachine";
import {TransitionConstants} from "../../stateMachines/StateConstants";
import {SetCardAccessToken} from "../Card/SetCardAccessToken";
import {SetCardData} from "../Card/SetCardData";

export class ClearAuthState {
    public static init(currentAuthState: string){
        SetCardAccessToken.init('');
        SetCardData.init({
            cardData: {
                lastFourDigitsOfCardNumber: '',
            }
        });
        const resultantState: StateValue = AuthenticationStateMachine.transition(currentAuthState, TransitionConstants.EXIT_CARD_ONLY_USER).value
        store.store.dispatch({type: SET_AUTH_STATE, currentAuthState: resultantState})
    }
}