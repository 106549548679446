import { Typography } from '@material-ui/core'
import React, { ReactNode } from 'react'

interface PageDescriptionProps {
  children: ReactNode
  id?: string | undefined
}

export default function PageDescriptionComponent({ children, id }: PageDescriptionProps) {
  return <div id={id ?? undefined}><Typography>{children}</Typography></div>
}