import React, {useEffect, useState} from 'react'
import {ProgramSelectorStyles} from './ProgramSelectorStyles'
import {ClassNameMap} from '@material-ui/styles'
import {AxiosResponse} from 'axios'
import ProgramIdentificationInteractor from '../../../lib/useCases/ProgramIdentificationInteractor'
import {Constants} from '../../../core/Constants'
import {Grid} from '@material-ui/core'

interface Program {
  url: string
  name: string
}

export default function ProgramSelector() {
  const styles: ClassNameMap = ProgramSelectorStyles()
  const [programs, setPrograms] = useState([])

  useEffect(() => {
    async function getPrograms() {
      try {
        const response: AxiosResponse = await ProgramIdentificationInteractor.getPrograms()
        if (response.status === Constants.HTTP_OK) {
          setPrograms(response.data.data)
        }
      } catch (e) {
        console.warn(e)
      }
    }
    getPrograms()
  }, [])

  const switchPrograms = (event: any) => {
    localStorage.setItem('program_origin', event.target.value)
    window.location.reload()
  }

  return (
    <Grid
      container
      className={styles.wrapper}
      direction={'row'}
      justify={'center'}
    >
      <div>
        <h5>Program Selector</h5>
        <select
          name="program-selector"
          id="program-selector"
          onChange={event => switchPrograms(event)}
          value={localStorage.getItem('program_origin') || 'none'}
        >
          <option value={'none'}>None</option>
          {programs.map((program: Program, index: number) => {
            return (
              <option key={`${program.url}-${index}`} value={program.url}>
                {program.name}
              </option>
            )
          })}
        </select>
      </div>
    </Grid>
  )
}
