import {makeStyles} from '@material-ui/core/styles'
import {Theme} from '@material-ui/core'

export const ResponsiveConfirmationDialogComponentStyles = makeStyles((theme:Theme) => ({
  dialogContainer: {
    borderRadius: '10px',
    maxWidth: '400px',
    width: '100%',
    textAlign: 'center',
    padding: '1em 2em',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.components.modal.closeButtonIconColor
  },
  icon: {
    padding: '1em',
    color: theme.components.modal.confirmationIconColor
  },
  title: {
    ...theme.typography.h2
  },
  subtitle: {
    ...theme.typography.h3
  },
  body: {
    ...theme.typography.body1
  },
}))