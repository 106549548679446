import React, {ChangeEvent, MouseEvent, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {RootStateOrAny, useSelector} from 'react-redux'
import {ProviderContext, useSnackbar} from 'notistack'
import {Grid} from '@material-ui/core'
import CardVerifiedInteractor from '../../../lib/useCases/CardVerifiedInteractor'
import PageTitleComponent from '../../Common/PageTitle/PageTitleComponent'
import PrimaryButtonComponent from '../../Common/PrimaryButton/PrimaryButtonComponent'
import SecondaryButtonComponent from '../../Common/SecondaryButton/SecondaryButtonComponent'
import StyledTextFieldComponent from '../../Common/StyledTextField/StyledTextFieldComponent'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronCircleLeft} from '@fortawesome/free-solid-svg-icons'
import {AxiosResponse} from 'axios'
import {VINValidationComponent} from '../../Validation/VINValidation/VINValidationComponent'
import {SSNValidationComponent} from '../../Validation/SSN/SSNValidationComponent'
import SnackbarUtils from '../../../modals/SnackbarNotificationModel'
import {SetCardAccessToken} from '../../../lib/businessObjects/Card/SetCardAccessToken'
import {ClearAuthState} from '../../../lib/businessObjects/Auth/ClearAuthState'
import {ExecuteSecondaryValidationAction} from '../../../lib/businessObjects/Card/ExecuteSecondaryValidationAction'
import {PhoneValidationComponent} from '../../Validation/PhoneValidation/PhoneValidationComponent'
import LoadingWidget from '../../Common/LoadingWidget/LoadingWidget'
import {useProgramRules} from '../../../hooks/useProgramRules'
import {CardValidationError} from '../../../lib/errors/CardValidationError'

const MAX_CARD_NUMBER_COUNT: number = 16

interface VerifyCardFormInterface {
  pageTitle: string
  route: string
  buttonText: string
  icon?: JSX.Element
  errorMessage: string
  cardActivationStatusAction: (isCardActive: number) => Promise<any> | void
}

export default function VerifyCardForm(props: VerifyCardFormInterface) {
  const history: any = useHistory()
  const programRules: any = useProgramRules()
  const {enqueueSnackbar}: ProviderContext = useSnackbar()
  const {currentAuthState}: RootStateOrAny = useSelector<RootStateOrAny>(
    state => state.auth,
  )

  const [loading, setLoading] = useState<boolean>(false)
  const [cardNumber, setCardNumber] = useState<string>('')
  const [secondaryInputValue, setSecondaryInformationValue] = useState<string>(
    '',
  )
  const [secondaryValidationType, setSecondaryValidationType] = useState<
    string
  >('')
  const [incorrectSecondaryInput, setIncorrectSecondaryInput] = React.useState(
    true,
  )

  const isValidCardLength = (cardNumber: string) =>
    cardNumber.length >= MAX_CARD_NUMBER_COUNT
  function getSecondaryInputBasedOnConfiguration() {
    const secondaryInputFormElementConfig: any = {
      vin: (
        <VINValidationComponent
          setSecondaryInformationValue={setSecondaryInformationValue}
          incorrectSecondaryInput={incorrectSecondaryInput}
          setIncorrectSecondaryInput={setIncorrectSecondaryInput}
        />
      ),
      ssn: (
        <SSNValidationComponent
          setSecondaryInformationValue={setSecondaryInformationValue}
          incorrectSecondaryInput={incorrectSecondaryInput}
          setIncorrectSecondaryInput={setIncorrectSecondaryInput}
        />
      ),
      phone: (
        <PhoneValidationComponent
          setSecondaryInformationValue={setSecondaryInformationValue}
          incorrectSecondaryInput={incorrectSecondaryInput}
          setIncorrectSecondaryInput={setIncorrectSecondaryInput}
        />
      ),
    }

    try {
      return secondaryInputFormElementConfig[secondaryValidationType]
    } catch (error) {
      return null
    }
  }

  const submitAction = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    if (!isValidCardLength(cardNumber)) {
      return enqueueSnackbar('Invalid Card', {
        variant: 'error',
      })
    }
    let submitAction

    if (!secondaryValidationType) {
      submitAction = async () => {
        setLoading(true)
        try {
          const programIds = programRules.associatedIds.map(function(rule: {
            id: string
          }) {
            return rule.id
          })
          console.log({programIds})
          const validationResponse:
            | AxiosResponse
            | any = await CardVerifiedInteractor.validateCardWithinOurSystem(
            cardNumber,
            programIds,
          )
          SetCardAccessToken.init(
            validationResponse.data.data.validation_access_token,
          )
          const validationType: AxiosResponse | any =
            validationResponse.data.data.validation_type
          if (validationType === 'none') {
            const authenticationResponse: AxiosResponse = await CardVerifiedInteractor.authenticateCardWithinOurSystemWithNoSecondaryValidation()
            await ExecuteSecondaryValidationAction.init(
              authenticationResponse,
              cardNumber,
              currentAuthState,
              history,
              props.route,
              props.cardActivationStatusAction,
            )
          } else {
            setSecondaryValidationType(validationType)
          }
        } catch (e) {
          switch (e.constructor) {
            case CardValidationError: {
              SnackbarUtils.error(props.errorMessage)
            }
          }
        }
        setLoading(false)
      }
    } else {
      submitAction = async () => {
        setLoading(true)
        const secondaryInputMapping: any = {
          vin: async () => {
            try {
              const authenticationResponse: AxiosResponse = await CardVerifiedInteractor.authenticateCardWithinOurSystemWithVINSecondaryValidation(
                secondaryInputValue.toString(),
              )
              await ExecuteSecondaryValidationAction.init(
                authenticationResponse,
                cardNumber,
                currentAuthState,
                history,
                props.route,
                props.cardActivationStatusAction,
              )
            } catch (error) {
              SnackbarUtils.error(props.errorMessage)
            }
          },
          ssn: async () => {
            try {
              const authenticationResponse: AxiosResponse = await CardVerifiedInteractor.authenticateCardWithinOurSystemWithSSNSecondaryValidation(
                secondaryInputValue.toString(),
              )
              await ExecuteSecondaryValidationAction.init(
                authenticationResponse,
                cardNumber,
                currentAuthState,
                history,
                props.route,
                props.cardActivationStatusAction,
              )
            } catch (error) {
              SnackbarUtils.error(props.errorMessage)
            }
          },
          phone: async () => {
            try {
              const authenticationResponse: AxiosResponse = await CardVerifiedInteractor.authenticateCardWithinOurSystemWithPhoneSecondaryValidation(
                secondaryInputValue.toString(),
              )
              await ExecuteSecondaryValidationAction.init(
                authenticationResponse,
                cardNumber,
                currentAuthState,
                history,
                props.route,
                props.cardActivationStatusAction,
              )
            } catch (error) {
              SnackbarUtils.error(props.errorMessage)
            }
          },
          code: () => {
            SnackbarUtils.error('Unsupported Card. Contact Customer Support')
          },
          mdeid: () => {
            SnackbarUtils.error('Unsupported Card. Contact Customer Support')
          },
        }
        secondaryInputMapping[secondaryValidationType]()
        setLoading(false)
      }
    }
    await submitAction()
  }

  let secondaryInput: any = getSecondaryInputBasedOnConfiguration()

  if (loading) return <LoadingWidget />

  return (
    <Grid container direction={'row'} justify={'center'}>
      <Grid item>
        <PageTitleComponent>{props.pageTitle}</PageTitleComponent>
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <StyledTextFieldComponent
          id={'card-number'}
          testId={'card-number-input'}
          label={'Enter Card Number'}
          autoFocus={true}
          autoComplete={'off'}
          value={cardNumber}
          disabled={typeof secondaryInput !== 'undefined'}
          required={true}
          inputProps={{
            maxLength: MAX_CARD_NUMBER_COUNT,
          }}
          onInput={(e: ChangeEvent<HTMLInputElement>) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, '')
          }}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setCardNumber(event.target.value)
          }
        />
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        {secondaryInput}
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <PrimaryButtonComponent
          disabled={
            cardNumber.length < MAX_CARD_NUMBER_COUNT ||
            (secondaryInputValue !== '' && incorrectSecondaryInput)
          }
          onClick={(event: MouseEvent<HTMLButtonElement>) =>
            submitAction(event)
          }
          testId={'card-submission-button'}
          text={props.buttonText}
          icon={props.icon}
        />
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        {typeof secondaryInput !== 'undefined' && (
          <SecondaryButtonComponent
            disabled={cardNumber.length < MAX_CARD_NUMBER_COUNT}
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              setSecondaryInformationValue('')
              setSecondaryValidationType('')
              setCardNumber('')
              ClearAuthState.init(currentAuthState)
            }}
            text={'Back'}
            icon={<FontAwesomeIcon icon={faChevronCircleLeft} />}
          />
        )}
      </Grid>
    </Grid>
  )
}
