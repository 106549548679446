import React, {ChangeEvent} from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  Typography,
  IconButton,
  NativeSelect,
} from '@material-ui/core'
import {ClassNameMap} from '@material-ui/styles'
import {ProviderContext, useSnackbar} from 'notistack'
import {useQuery, useQueryClient} from 'react-query'
import CardInformationInteractor from '../../../lib/useCases/CardInformationInteractor'
import {
  CardTransactionHistoryComponentStyles,
  ErrorTransactionsHistoryComponentStyles,
} from './CardTransactionHistoryComponentStyles'
import LoadingWidget from '../../Common/LoadingWidget/LoadingWidget'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronRight, faChevronLeft} from '@fortawesome/free-solid-svg-icons'

interface ICardTransaction {
  datetime: Date
  location: string
  amount: string
  transactionType: string
}

const getTransactionSymbol = (transactionType: string) => {
  if (transactionType.trim().toLowerCase() === 'debit') {
    return '-'
  }
  if (transactionType.trim().toLowerCase() === 'credit') {
    return '+'
  }
  return ''
}

const ErrorTransactionsHistoryComponent = () => {
  const styles: ClassNameMap<'container'> = ErrorTransactionsHistoryComponentStyles()
  return (
    <Paper className={styles.container}>
      Unable to retrieve transaction history.
    </Paper>
  )
}

export default function CardTransactionHistoryComponent() {
  const queryClient = useQueryClient()
  const styles: ClassNameMap = CardTransactionHistoryComponentStyles()
  const {enqueueSnackbar}: ProviderContext = useSnackbar()

  const [page, setPage] = React.useState(1)
  const [rows, setRows] = React.useState(10)

  const {data, isLoading, isError, isPreviousData} = useQuery(
    ['getCardTransactions', {offset: page, limit: rows}],
    () =>
      CardInformationInteractor.getCardTransactions({
        offset: page,
        limit: rows,
      }),
    {
      keepPreviousData: true,
      onError: () => {
        enqueueSnackbar('Unable to retrieve transaction history', {
          variant: 'error',
        })
      },
      onSuccess: () => {
        queryClient.prefetchQuery(
          ['getCardTransactions', {offset: page + 1, limit: rows}],
          () =>
            CardInformationInteractor.getCardTransactions({
              offset: page + 1,
              limit: rows,
            }),
        )
      },
    },
  )

  if (isError) {
    return <ErrorTransactionsHistoryComponent />
  }

  return (
    <div className={styles.root}>
      {isLoading || isPreviousData ? <LoadingWidget /> : null}

      <TableContainer
        component={Paper}
        className={styles.tableContainer}
        elevation={1}
      >
        <Typography className={styles.tableTitle} align="left" gutterBottom>
          Transaction History
        </Typography>

        <Table aria-label="customized table" className={styles.table}>
          <TableHead>
            <TableRow className={styles.tableHead}>
              <TableCell className={styles.tableHead}>Date</TableCell>
              <TableCell className={styles.tableHead} align="left">
                Description
              </TableCell>
              <TableCell className={styles.tableHead} align="right">
                Amount
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data &&
              data.map((transaction: ICardTransaction, index: number) => (
                <TableRow
                  hover={true}
                  key={`${index}-${transaction.datetime}-${transaction.location}`}
                >
                  <TableCell
                    className={styles.tableBodyCell}
                    component="th"
                    scope="transaction"
                  >
                    {new Date(transaction.datetime).toDateString()}
                  </TableCell>
                  <TableCell className={styles.tableBodyCell} align="left">
                    {transaction.location}
                  </TableCell>
                  {/* prettier-ignore */}
                  <TableCell className={styles.tableBodyCell} align="right">
                  {`${getTransactionSymbol(transaction.transactionType)}$${transaction.amount}`}
                </TableCell>
                </TableRow>
              ))}
            {!data?.length && !isLoading && (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  There are no more transactions.
                </TableCell>
              </TableRow>
            )}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align="right">
                <div className={styles.tablePaginationGroup}>
                  <div className={styles.tablePaginationAction}>
                    <label htmlFor="rows">Items per page:</label>
                    <NativeSelect
                      value={rows}
                      onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                        setRows(Number(event.target.value))
                        setPage(1)
                      }}
                      name="rows"
                      classes={{root: styles.tablePaginationSelect}}
                      inputProps={{'aria-label': 'rows'}}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                    </NativeSelect>
                  </div>
                  <div className={styles.tablePaginationAction}>
                    <p>Page:</p>
                    <p>{page}</p>
                  </div>
                  <div>
                    <IconButton
                      className={styles.tablePaginationArrows}
                      onClick={() => setPage(page => Math.max(page - 1, 0))}
                      disabled={page === 1}
                    >
                      <FontAwesomeIcon size="xs" icon={faChevronLeft} />
                    </IconButton>
                    <IconButton
                      className={styles.tablePaginationArrows}
                      onClick={() => {
                        if (!isPreviousData && data?.length) {
                          setPage(page => page + 1)
                        }
                      }}
                      disabled={
                        isPreviousData || !data?.length || data?.length < rows
                      }
                    >
                      <FontAwesomeIcon size="xs" icon={faChevronRight} />
                    </IconButton>
                  </div>
                </div>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  )
}
