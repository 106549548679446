import {makeStyles} from '@material-ui/core/styles'
import {Theme} from '@material-ui/core'

export const RootLayoutStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.components.root.color,
    backgroundColor: theme.components.root.backgroundColor,
    height: '100%',
    width: '100%',
    margin: 0,

    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
}))