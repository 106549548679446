import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

export const BaseActivateCardLayoutStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        padding: '100px 30px',
        textAlign: 'center',
        paddingBottom: '5em',
    },
}))
