import {StateConstants} from './StateConstants'

export default class AuthenticationStateMachineInteractor {
  public static userIsAuthenticated(currentAuthState: string) {
    return (
      currentAuthState !== undefined &&
      currentAuthState === StateConstants.AUTHENTICATED
    )
  }

  public static userIsCardVerified(currentAuthState: string) {
    return (
      currentAuthState !== undefined &&
      currentAuthState === StateConstants.CARD_USER_ONLY
    )
  }

  public static userIsUnauthenticated(currentAuthState: string) {
    return (
      currentAuthState !== undefined &&
      currentAuthState === StateConstants.UNAUTHENTICATED
    )
  }
}
