import {createBrowserHistory} from 'history';

/**
 * Exporting createBrowserHistory() allows us to make changes to the routing history from JavaScript objects.
 */
const history = createBrowserHistory();
history.listen((location) => {

});

export default history;