import React, { ChangeEvent } from 'react'
import StyledTextFieldComponent from '../../Common/StyledTextField/StyledTextFieldComponent'
import {ValidateInput} from "../../../lib/businessObjects/Validation/ValidateInput";

interface VINValidationComponentInterface {
    setSecondaryInformationValue: (value: any) => void;
    incorrectSecondaryInput: boolean;
    setIncorrectSecondaryInput: (value: any) => void
}

export function VINValidationComponent(props: VINValidationComponentInterface) {
    return (
        <StyledTextFieldComponent
            id="secondary-information-number"
            label="Enter the last 6 digits of your VIN"
            type="number"
            required={true}
            error={props.incorrectSecondaryInput}
            inputProps={{
                maxLength: 6,
            }} 
            onInput={(element: ChangeEvent<HTMLInputElement>) => {
                if(!ValidateInput.stringIsValidAlphaNumericNumberOfDigits(element.target.value.toString(), 6)) {
                    props.setIncorrectSecondaryInput(true);
                } else {
                    props.setIncorrectSecondaryInput(false);
                }
            }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => props.setSecondaryInformationValue(event.target.value)}
        />
    )
}